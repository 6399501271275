app.directive('pageSlideshow', function () {
    return {
        templateUrl: '/views/exhibit/interactives/page_slideshow.html',
        replace : true,
        scope:{
            "data":"=data"
        },
        controller: function ($scope,$rootScope) { $scope.serverUrl = $rootScope.serverUrl;
            
            $scope.altImage = false;

            $scope.getClass = function(idx){
                if($scope.current == idx){
                    return 'current';
                }
                else if(repeat($scope.current+1,$scope.data.interactive.images.length) == idx){
                    return 'right';
                }
                else if(repeat($scope.current-1,$scope.data.interactive.images.length)  == idx){
                    return 'left';
                }
            }
            $scope.move = function(dir){
                $scope.altImage = false;
                $scope.current = repeat($scope.current + dir,$scope.data.interactive.images.length);
            }
            function repeat(number,length){
                if(number > -1){
                    return number % length;
                }
                else{
                    return length -1;
                }
            }

            $scope.toggleAltImage = function()
            {
                console.log("toggle images");
                $scope.altImage = !$scope.altImage;
            }

        }
    };
});
