app.directive('pageListen', function () {
    return {
        templateUrl: '/views/exhibit/interactives/page_listen.html',
        replace : true,
        scope:{
            "data":"=data"
        },
        controller: function ($scope,$rootScope) { $scope.serverUrl = $rootScope.serverUrl;
            $scope.lastTrackID = -1;
            $scope.altImage = false;

            $scope.play = function(idx){
                $scope.currentTrack = {
                    "id":idx,
                    "track":$rootScope.trustSrc($rootScope.serverUrl + $scope.data.interactive.audio[idx])
                };
                var element = document.getElementById('audio-player');
                if(!element.paused && idx == $scope.lastTrackID){
                    element.pause();   
                }
                else{
                    $scope.lastTrackID = idx;
                    setTimeout(function(){
                         element.play();
                    },100);
                   
                }

                $scope.singleStyle = {'transform': 'scale(1.5)'};
            }

            $scope.toggleAltImage = function()
            {
                console.log("toggle images");
                $scope.altImage = !$scope.altImage;
            }

        }
    };
});
