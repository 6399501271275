app.controller('mainCtrl', function ($scope, KMService, Api, $location,$sce,$rootScope,$timeout) {
    // $rootScope.serverUrl = "http://192.168.41.17:8080/";
    $rootScope.serverUrl = "https://digitalescheurkalender.com/";
    // $rootScope.serverUrl = "http://localhost:8080/";
    $rootScope.title = 'Digitale Scheurkalender';
    $rootScope.free = false;
    $rootScope.trustSrc = function(src) {
        console.log("TRUSTY",$sce);
        return $sce.trustAsResourceUrl(src);
    }
    $scope.$on("$routeChangeError", function(evt,current,previous,rejection){
        $location.path("/");
        if(rejection == "not_logged_in"){
            //DO SOMETHING
        } else {
            //OR DO SOMETHING ELSE
        }
    });

    // force scroll to top for (mobile) safari
    $timeout(function(){
        window.scrollTo(0, 0);
    },1000); // 1 dec delay required for mobile
    
});