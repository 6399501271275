app.directive('pageVideoSlideshow', function () {
    return {
        templateUrl: '/views/exhibit/interactives/page_videoslideshow.html',
        replace : true,
        scope:{
            "data":"=data"
        },
        controller: function ($scope,$rootScope) { 
            $scope.serverUrl = $rootScope.serverUrl;
            $scope.getClass = function(idx){
                if($scope.current == idx){
                    return 'current';
                }
                else if(repeat($scope.current+1,$scope.data.interactive.videos.length) == idx){
                    return 'right';
                }
                else if(repeat($scope.current-1,$scope.data.interactive.videos.length)  == idx){
                    return 'left';
                }
            }
            for(var i in $scope.data.interactive.videos){
                $scope.data.interactive.videos[i].video = $rootScope.trustSrc($rootScope.serverUrl + $scope.data.interactive.videos[i].image);
                // console.log($scope.data.interactive.videos[i]);
            }
            $scope.move = function(dir){
                $scope.current = repeat($scope.current + dir,$scope.data.interactive.videos.length); 
                stopAll();
            }
            function repeat(number,length){
                if(number > -1){
                    return number % length;
                }
                else{
                    return length -1;
                }
            }
            function stopAll(){
                var vids = document.getElementsByTagName('video') 
                // vids is an HTMLCollection
                for( var i = 0; i < vids.length; i++ ){ 
                    vids[i].classList.remove("playing");
                    vids[i].pause();
                }
            }
            $scope.toggle = function(event){
                console.log(event);
                if(event.target.paused){
                    playVideo(event.target);
                }
                else{
                    pauseVideo(event.target);
                }
            }
            function playVideo(el){
                console.log("Play",el);
                el.addEventListener('ended',function(){ pauseVideo(el) },false);
                el.classList.add('playing');
                el.play();
            }
            function pauseVideo(el){
                console.log("Pause",el);
                el.classList.remove('playing');
                el.pause();
            }
        }
    };
});

