app.directive('pageDeepen', function () {
    return {
        templateUrl: '/views/exhibit/interactives/page_deepen.html',
        replace : true,
        scope:{
            "data":"=data"
        },
        controller: function ($scope,$rootScope,Analytics) { $scope.serverUrl = $rootScope.serverUrl;

            $scope.overlayVisible = false;
            console.log($scope.data);

            $scope.altImage = false;
            
            $scope.toggleOverlay = function(e)
            {
                console.log("toggleOverlay");
                if(!$scope.overlayVisible){
                    Analytics.trackEvent('overlay', 'open', $scope.data);
                }
                else{
                    //  Analytics.trackEvent('overlay', 'close', $scope.videourl);
                }
                $scope.overlayVisible = !$scope.overlayVisible;
                var element = document.getElementById("overlay");
            }

            $scope.toggleAltImage = function()
            {
                console.log("toggle images");
                $scope.altImage = !$scope.altImage;
            }

        }
    };
});
