
app.config(function ($routeProvider,$locationProvider) {

	$routeProvider
		.when('/', {
			controller: 'LoginCtrl',
			templateUrl: '/views/exhibit/login.html',
			resolve:{
				data:function(){
					return "Start";
				}
			}
		})
		.when('/overview', {
			controller: 'OverviewCtrl',
			templateUrl: '/views/exhibit/overview.html'
		})
		.when('/open/:token', {
            controller: 'DeeplinkCtrl',
            templateUrl: '/views/exhibit/overview.html'
		})
		.when('/collections', {
			controller: 'CalendarCollectionsCtrl',
			templateUrl: '/views/exhibit/calendar_collections.html',
			resolve:{
				auth:CheckAuth
			}
		})
		.when('/calendars/:id/:name', {
			controller: 'CalendarsCtrl',
			templateUrl: '/views/exhibit/calendars.html',
			resolve:{
				auth:CheckAuth
			}
		})
		.when('/calendars/:id', {
			controller: 'CalendarsCtrl',
			templateUrl: '/views/exhibit/calendars.html',
			resolve:{
				auth:CheckAuth
			}
		})
		.when('/calendar/page/:id/:name/:page', {
			controller: 'CalendarPageCtrl',
			templateUrl: '/views/exhibit/calendar_page.html',
			resolve:{
				auth:CheckAuth
			}
		})
		.when('/calendar/page/:id/:name/:page/:slide', {
			controller: 'CalendarPageCtrl',
			templateUrl: '/views/exhibit/calendar_page.html',
			resolve:{
				auth:CheckAuth
			}
		})
		.when('/calendar/page/:id', {
			controller: 'CalendarPageCtrl',
			templateUrl: '/views/exhibit/calendar_page.html',
			resolve:{
				auth:CheckAuth
			}
		})
		.otherwise({
			redirectTo: '/'
		});


		function CheckAuth($q, $rootScope,Api){
			// return true;
			var defer = $q.defer();
			if(typeof $rootScope.user != 'undefined' || $rootScope.free || $rootScope.deeplinkCollection)
			{
				defer.resolve();
			}
			else{
				Api.Get("auth/random-number",function(res){
					if(typeof res.data.user != 'undefined'){
						$rootScope.user = res.data.user;
						defer.resolve();
					}
					else{
						defer.resolve();
						// defer.reject("not_logged_in");
					}
				},function(err){
					defer.resolve();
					// defer.reject("not_logged_in");
				});
			}
			return defer.promise;
		}
}); 