app.directive('pageWatch', function () {
    return {
        templateUrl: '/views/exhibit/interactives/page_watch.html',
        replace : true,
        scope:{
            "data":"=data"
        },
        controller: function ($scope,$rootScope,$window,$timeout) { $scope.serverUrl = $rootScope.serverUrl;
            
            $scope.zoomFactor = 3;
            $scope.altImage = false;

            // WATCH
            $scope.initMagnifier = function()
            {

                // get elements
                var magnifier = document.getElementById("mag");
                var magImg = document.getElementById("magImg");
                var smallImg = document.getElementById("small");

                // make backgroundimage twice as big as original
                //magnifier.style.backgroundSize = toVh(smallImg.clientWidth*$scope.zoomFactor) + 'vh' + " " + toVh(smallImg.clientHeight*$scope.zoomFactor) + 'vh';
                magnifier.style.backgroundSize = (smallImg.clientWidth*$scope.zoomFactor) + 'px' + " " + (smallImg.clientHeight*$scope.zoomFactor) + 'px';

                // let myX = magnifier.style.left + (magnifier.style.right - magnifier.style.left);
                // let myY = magnifier.style.top  + (magnifier.style.bottom - magnifier.style.top);
                
                let myX = smallImg.x;// + smallImg.width/2;
                let myY = smallImg.y;// + smallImg.height/2;

                magnifier.style.left    = magImg.style.left  = (myX) + 'px';
                magnifier.style.top     = magImg.style.top   = (myY) + 'px';
                
                // position background image of magnifier
                magnifier.style.backgroundPositionX = -(myX*$scope.zoomFactor) + (smallImg.getBoundingClientRect().left*$scope.zoomFactor) + (magnifier.clientWidth/2) + 'px';
                magnifier.style.backgroundPositionY = -(myY*$scope.zoomFactor) + (smallImg.getBoundingClientRect().top*$scope.zoomFactor) + (magnifier.clientHeight/2) + 'px';
                $scope.dingenTwo = smallImg.getBoundingClientRect();
                /* 
                // position magnifier + mag image to mouse position
                magnifier.style.left    = magImg.style.left     = toVh(720-(magnifier.clientWidth>>1)) + 'vh';
                magnifier.style.top     = magImg.style.top      = toVh(320-(magnifier.clientHeight>>1)) + 'vh';
                
                // position background image of magnifier
                magnifier.style.backgroundPositionX = toVh(-( 720*$scope.zoomFactor - (smallImg.offsetLeft*$scope.zoomFactor) ) + (magnifier.clientWidth/2)) + 'vh';
                magnifier.style.backgroundPositionY = toVh(-( 320*$scope.zoomFactor - (smallImg.offsetTop*$scope.zoomFactor) ) + (magnifier.clientHeight/2)) + 'vh';
                 */
            }
            $scope.leaveMagnifier = function()
            {
                // show cursor
                document.body.style.cursor = "default";
            }
            $scope.moveMagnifier = function(evt)
            {

                // get elements
                var magnifier = document.getElementById("mag");     // glass
                var magImg = document.getElementById("magImg");     // frame + handle
                var smallImg = document.getElementById("small");

                var eventSpace = document.getElementById("eventSpace");
                var leaf = document.getElementById("leaf");

                // hide cursor
                document.body.style.cursor = "none";

                // make backgroundimage twice as big as original
                magnifier.style.backgroundSize = (smallImg.clientWidth*$scope.zoomFactor) + 'px' + " " + (smallImg.clientHeight*$scope.zoomFactor) + 'px';
                
                //let myX = evt.center.x - magImg.width*2;
                let myX = evt.center.x - magImg.width/2;
                let myY = evt.center.y - magImg.height/2;
                $scope.dingen = evt.center;
                // $scope.dingenTwo = smallImg.getBoundingClientRect();
                //console.log(evt);

                // position magnifier + mag image to mouse position
                magnifier.style.left    = magImg.style.left  = (myX) + 'px';
                magnifier.style.top     = magImg.style.top   = (myY) + 'px';
                
                // position background image of magnifier
                var addHeight = ((smallImg.clientHeight*$scope.zoomFactor)/2);
                var addWidth = ((smallImg.clientWidth*$scope.zoomFactor)/2);
                if (!navigator.userAgent.match(/(iPad|iPhone|iPod)/i)){
                    addHeight = 0;
                    addWidth = 0;
                }
                magnifier.style.backgroundPositionX = -(myX*$scope.zoomFactor) + ($scope.dingenTwo.left*$scope.zoomFactor) + (magnifier.clientWidth/2) - addWidth + 'px';
                magnifier.style.backgroundPositionY = -(myY*$scope.zoomFactor) + ($scope.dingenTwo.top*$scope.zoomFactor) + (magnifier.clientHeight/2) - addHeight + 'px';

            } 
            
            function toVh(value){
                return value/(document.body.clientHeight/100);
            }
            
            $scope.launchWeblink = function(url)
            {
                $window.open( url );
            }
            
            $scope.init = function()
            {
                $timeout(function(){
                    var magnifier = document.getElementById("mag");
                    if (magnifier!=undefined)
                    {
                        $scope.initMagnifier();
                    }
                });
            }

            $scope.toggleAltImage = function()
            {
                console.log("toggle images");
                $scope.altImage = !$scope.altImage;

                var magnifier = document.getElementById("mag");
                console.log(magnifier);
                if (magnifier!=undefined)
                {
                    $scope.initMagnifier();
                }
            }

            $scope.init();
            
            
        }
    };
});
