app.directive('pageYoutube', function () {
    return {
        templateUrl: '/views/exhibit/interactives/page_youtube.html',
        replace : true,
        scope:{
            "data":"=data"
        },
        controller: function ($scope,$rootScope,$sce,$timeout,Analytics,ngYoutubeEmbedService) {

            $scope.trustSrc = function(src) {
                return $sce.trustAsResourceUrl(src);
            }

             $scope.serverUrl = $rootScope.serverUrl;
             // VIDEO
            $scope.videourl = "";
            $scope.videoOverlayVisible = false;
            $scope.videourl = $scope.data.interactive.youtubeurl;
            $scope.startTime = 0;

            //TIMESTAMP EXTRACTION
            $scope.getStartTime = function(url){
                //TIMESTAMP EXTRACTION

                var pattern = /t=(.*s)/g;
                var match = url.match(pattern);
                if(match == null){
                    return 0;
                }
                var timestamp = match[0];
                // console.log(timestamp);
                if(timestamp.length > 0){
                    var total = 0;

                    var mPattern = /(\d+)m/g;
                    var minutes =  timestamp.match(mPattern)[0];
                    if(minutes.length > 0){
                        var number = parseInt(minutes.substring(0, minutes.length - 1)*60);
                        total += number;
                        // console.log(total,number,minutes);
                    }

                    var secPattern = /(\d+)s/g;
                    var seconds =  timestamp.match(secPattern)[0];
                    if(seconds.length > 0){
                        var number = parseInt(seconds.substring(0, seconds.length - 1));
                        total += number;
                        // console.log(total,number,seconds)
                    }
                    return total;

                }
                return 0;
            }


            $scope.altVideo = false;


            $scope.toggleVideoOverlay = function(e)
            {
                // if(!$scope.overlayVisible){
                //     Analytics.trackEvent('video', 'play', $scope.videourl);
                // }
                // $scope.videourl = $rootScope.trustSrc($rootScope.serverUrl + $scope.data.interactive.videourl);
                // console.log("toggleVideoOverlay",$scope.videourl);
            
                
                console.log($scope.data.interactive.youtubeurl);
                if($scope.data.interactive.youtubeurl !== undefined){
                    $scope.videourl = $scope.data.interactive.youtubeurl.replace('watch?v=', 'embed/') + "?autoplay=1&rel=0";
                }
                $scope.videoOverlayVisible = !$scope.videoOverlayVisible;
                // var element = document.getElementById("player");
                // var videoElement = angular.element(element);
                // if($scope.videoOverlayVisible){
                    //     videoElement[0].play();
                    // }
                    // else{
                        //     videoElement[0].pause();
                        // }
                    
                        // console.log(ngYoutubeEmbedService);
                        // $timeout(function () {
                        // }, 3500);
                        // player.playVideo();

                
                // var player = ngYoutubeEmbedService.getPlayerById('myvideo'); // Get iframe player instance
                // console.log(player);
                // if($scope.videoOverlayVisible){
                //     player.playVideo();
                // }
                // else{
                //     player.pauseVideo();
                // }
             
            }

            $scope.toggleAltVideo = function()
            {
                console.log("toggle video");
                $scope.altVideo = !$scope.altVideo;
                if ($scope.altVideo == true)
                {
                    $scope.videourl = $scope.data.interactive.altyoutubeurl;
                }
                else
                {
                    $scope.videourl = $scope.data.interactive.youtubeurl.replace('watch?v=', 'embed/');
                }
            }

        }
    }
});
