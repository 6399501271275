app.directive('pageQa', function () {
    return {
        templateUrl: '/views/exhibit/interactives/page_qa.html',
        replace : true,
        scope:{
            "data":"=data"
        },
        controller: function ($scope,$rootScope) { $scope.serverUrl = $rootScope.serverUrl;
            $scope.altQuestion = false;
            $scope.altAnswer = false;

            $scope.toggleAltQuestion = function()
            {
                console.log("toggle images");
                $scope.altQuestion = !$scope.altQuestion;
            }
            $scope.toggleAltAnswer = function()
            {
                console.log("toggle images");
                $scope.altAnswer = !$scope.altAnswer;
            }
        }
    };
});
