app.controller('LoginCtrl', function ($scope,$rootScope,Api,$route,KMService,$routeParams, $location,data, Page, $anchorScroll,$window) 
{
	console.log(data);
    $rootScope.accessibility.isHighContrast = false;
    $scope.kmService = KMService;
	$scope.user = {};
		$scope.login = function()
		{
			$scope.user.password = $scope.user.name;
			Api.Post("auth/login",$scope.user, function (res) {
				$rootScope.user = res.data;
				$rootScope.free = false;
				$location.path("/collections");
			},function(error){
				console.log("error", error);
				$scope.invalidSchool = "true";
                $scope.invalidMsgSchool = "toegangscode onbekend";
			});
		}

		Api.Get("collections/list-all", function (res) {
			$scope.allCals = res.data;
		},function(error){
			console.log("List-all error " + error);
		});

		$scope.openFeaturedCalendar = function(calendar)
		{
			console.log("Open calendar by id " + calendar._id);
			$rootScope.free = true;
            console.log(calendar);
            const collectionID = calendar._id;
            const collectionName = $scope.kmService.slugify(calendar.name);
            $location.path("/calendars/" + collectionID + "/" + collectionName );
		}

		$scope.loginHubs = function()
		{
			console.log("$scope.hub",$scope.hub);
			Api.Post("auth/login",$scope.hub, function (res) {
				if(res.data.rol != 'user'){
					console.log("$scope.hub",$scope.hub);
					$rootScope.user = res.data;
					$window.location.href = "/admin/#!/main";
				}
				else{
					$scope.invalidHub = "true";
                    $scope.invalidMsgHub = "U hebt geen rechten om deze pagina te bekijken";
				}
			},function(error){
				console.log("error", error);
				$scope.invalidHub = "true" ;
                $scope.invalidMsgHub = "Ongeldige inloggegevens";
			});
		}


		$scope.discoverNow = function()
		{
			$scope.user.name = "nederland";
			// $scope.user.name = "ktfuser";
			$scope.login();
			// $anchorScroll("video");
		}

		$scope.showOverview = function()
		{
			console.log("Show overview");
			$location.path("/overview");
		}

});
