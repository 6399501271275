app.directive('pagePositioning', function () {
    return {
        templateUrl: '/views/exhibit/interactives/page_positioning.html',
        replace : true,
        scope:{
            "data":"=data"
        },
        controller: function ($scope,$rootScope) { $scope.serverUrl = $rootScope.serverUrl;

            $scope.image = $scope.data.interactive.object;

            $scope.getClass = function(idx){
                if($scope.current == idx){
                    return 'current';
                }
                else if(repeat($scope.current+1,$scope.data.interactive.images.length) == idx){
                    return 'right';
                }
                else if(repeat($scope.current-1,$scope.data.interactive.images.length)  == idx){
                    return 'left';
                }
            }
            
            $scope.onObjectMove = function(event)
            {

               // console.log(event);

                let l = event.center.x - event.target.offsetLeft - (event.target.clientWidth/2);
                let t = event.center.y - event.target.offsetTop - (event.target.clientHeight/2);

                $scope.draggedStyle = {
                    "transform": "translate(" + l + "px, " + t + "px)",
                    "-webkit-transform": "translate(" + l + "px, " + t + "px)"
                };
            }

            $scope.move = function(dir){
                $scope.current = repeat($scope.current + dir,$scope.data.interactive.images.length); 
            }

            function repeat(number,length){
                if(number > -1){
                    return number % length;
                }
                else{
                    return length -1;
                }
            }

        }
    };
});
