app.directive('markdown', function($parse, $q, $interval) {
  return {
    restrict: 'A',
    scope:{
        markdown : "@",
    },
    link : function(scope, element){
        // console.log("SCOPE",scope.markdown,scope);
        // if(typeof scope.markdown !='undefined'){
        //     var regex = /\*[a-zA-Z][\s\S]*?\*/g;
        //     var output = scope.markdown.replace(regex,'<i>$&</i>');
        //     output = output.replace(/\**?\*/g,'');
        //     // console.log(output);
        //     element.append(output);
        // }
        scope.$watch("markdown", function(n, o){
            if(typeof scope.markdown !='undefined'){
                var regex = /\*[a-zA-Z][\s\S]*?\*/g;
                var output = scope.markdown.replace(regex,'<i>$&</i>');
                output = output.replace(/\**?\*/g,'');
                // console.log(output);
                element.html(output);
            }
          })
    }
  };
});