app.controller('CalendarPageCtrl', function ($scope,$rootScope, $window,$timeout,$location, KMService, Api,$routeParams,Page,Analytics) 
{

    $scope.kmService = KMService;
    $scope.current = 0;
    $scope.numInteractives = 0;
    $scope.previousVisible = false;
    $scope.nextVisible = false;
	$scope.off = true;
    $scope.back = "calendars/0";
    console.log($location.search().id);
    $scope.focus = {
        '_id':$location.search().id
    };
    //Retrieve page data
    Api.Get("calendars/"+$routeParams.id, function (res) {
        //Set data and assign first as current
        Page.setTitle(res.data.name);
        $scope.pages = res.data;
        Analytics.trackEvent('navigation', 'started ' + $scope.pages.name);
        $scope.pages.pages.push(
            {
                "index":-1,
                "type":"main",
                "preview":res.data.preview,
                "name":res.data.name,
                "title":res.data.title,
                "year":res.data.year,
                "period":res.data.period,
            },
            {
                "index":res.data.pages.length,
                "type":"end",
                "end":res.data.end,
            }
        )

        // console.log($scope.filteredPages);
        // $scope.numInteractives = getPageLength();
        $scope.updateinteractive();
    },function(error){
        console.log(error);
        $location.path("/");
    });
    $scope.goBack = function(){
        if($scope.current !== getPageLength()-1){
             Analytics.trackEvent('navigation', 'exited '+$scope.pages.name+ ' before end');
        }
        const collectionID = $scope.pages.collections._id;
        const collectionName = $scope.kmService.slugify($scope.pages.collections.name);
        $location.path("/calendars/" + collectionID + "/" + collectionName );
    }
    $scope.nextinteractive = function()
    {
        $scope.current++;
        if ($scope.current >= getPageLength())
        {
            $scope.current = getPageLength()-1;
        }
        $scope.updateinteractive();
    }
    $scope.previousinteractive = function()
    {
        $scope.current--;
        if ($scope.current < 0)
        {
            $scope.current = 0;
        }
        $scope.updateinteractive();
    }

    $scope.goPage = function(pageNum)
    {
        if (pageNum != $scope.current)
        {
            $scope.current = pageNum;
            $scope.updateinteractive();
        }
    }

    $scope.updateinteractive = function()
    {
        $scope.off = false;
        // $scope.$apply();
        $scope.current === 0 ? $scope.previousVisible = false : $scope.previousVisible = true;
        $scope.current === getPageLength()-1 ? $scope.nextVisible = false : $scope.nextVisible = true;
        // $scope.currentPage = $scope.filteredPages[$scope.current];

        if(typeof $scope.filteredPages != 'undefined' && $scope.current < $scope.filteredPages.length && typeof $scope.filteredPages[$scope.current].type != 'undefined'){
            Analytics.trackPage($location.path() + "/" + ($scope.current+1),$scope.pages.name + " - " + $scope.filteredPages[$scope.current].type,
            { "dimension1" : $scope.filteredPages[$scope.current].type }
            );
        }
        if($scope.current === getPageLength()-1){
             Analytics.trackEvent('navigation', 'finished ' + $scope.pages.name);
        }
        console.log($rootScope.serverUrl,$scope.currentPage);
        $timeout(function(){$scope.off = true;},1);
    }
    function getPageLength(){
        return (typeof $scope.filteredPages == 'undefined') ? 0 : $scope.filteredPages.length;
    }

   
});
