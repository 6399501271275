app.directive('pageCompare', function () {
    return {
        templateUrl: '/views/exhibit/interactives/page_compare.html',
        replace : true,
        scope:{
            "data":"=data"
        },
        controller: function ($scope,$rootScope) { $scope.serverUrl = $rootScope.serverUrl;
            // COMPARE
            $scope.getCompareOrientationClass = function()
            {
                if ($scope.data.interactive.orientation != "vertical")
                {
                    return "crop-container-horizontal";
                }
                return "crop-container-vertical";
            }
        }
    };
});
