app.directive('pageColoring', function () {
    return {
        templateUrl: '/views/exhibit/interactives/page_coloring.html',
        replace : true,
        scope:{
            "data":"=data"
        },
        controller: function ($scope,$rootScope) { $scope.serverUrl = $rootScope.serverUrl;
            

            $scope.selectedColor;
            $scope.selectColor = function(color)
            {
                console.log("selectColor = " + color);
                var clrs = document.getElementsByClassName('color-bubble');

                for (let i = 0; i < clrs.length; i++) {
                    clrs[i].style.border = "none";
                    // clrs[i].style.borderRadius = "100%";
                }

                let clrArr = [];
                angular.forEach($scope.data.interactive.colors, function(element) {
                    clrArr.push(element);
                });
                //let clrArr = Array.prototype.slice.call($scope.data.interactive.colors);
                clrs[clrArr.indexOf(color)].style.border = "3px inset black";
                // clrs[$scope.data.interactive.colors.indexOf(color)].style.border = "3px inset black";
                // clrs[$scope.data.interactive.colors.indexOf(color)].style.borderRadius = "0";

                $scope.selectedColor = color;
            }
            
            

            $scope.drawing = document.getElementById('drawing');
            $scope.drawing.onload = function() {
                
                console.log("drawing loaded");

                $scope.selectColor($scope.data.interactive.colors[0]);

                $scope.canvas = document.getElementById('canvas_drawing');
                //$scope.canvas.width =  $scope.drawing.naturalWidth;
                //$scope.canvas.height =  $scope.drawing.naturalHeight;
    
                $scope.canvas.width =  $scope.drawing.clientWidth;
                $scope.canvas.height =  $scope.drawing.clientHeight;
                //$scope.canvas.left = $scope.drawing.offsetLeft;// + 'px';
                
                
                $scope.context = $scope.canvas.getContext('2d');

                //$scope.context.drawImage($scope.drawing, 0, 0); 
                $scope.context.drawImage($scope.drawing, 0, 0, $scope.drawing.naturalWidth, $scope.drawing.naturalHeight, 0, 0, $scope.canvas.width, $scope.canvas.height);

                window.onresize = function(event) {
                    $scope.canvas.width =  $scope.drawing.width;
                    $scope.canvas.height =  $scope.drawing.height;
                    $scope.context.drawImage($scope.drawing, 0, 0, $scope.drawing.naturalWidth, $scope.drawing.naturalHeight, 0, 0, $scope.canvas.width, $scope.canvas.height);
                };

                $scope.canvas.onclick = function(e){
                    //var x = e.pageX -  $scope.canvas.offsetLeft;
                    //var y = e.pageY -  $scope.canvas.offsetTop;
                    var x = e.offsetX;
                    var y = e.offsetY;
                    
                    // if black or grey (max 50%) then probably line so ignore click
                    var pixelData = $scope.context.getImageData(x, y, 1, 1).data;
                    if (pixelData[0] == pixelData[1] && pixelData[1] == pixelData[2]) // shade of grey
                    {
                        if (pixelData[0] < 127) // max 50% grey to catch anti aliasing
                        {
                            return;
                        }
                    }

                    $scope.context.fillStyle =  $scope.selectedColor;
                    $scope.context.fillFlood(x, y, 128, 0, 0, $scope.canvas.width, $scope.canvas.height);
                }
            }
            

        }
    };
});
