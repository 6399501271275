app.directive('pagePuzzle', function () {
    return {
        templateUrl: '/views/exhibit/interactives/page_puzzle.html',
        replace : true,
        scope:{
            "data":"=data"
        },
        controller: function ($scope,$rootScope,$timeout,$window) {
            $scope.serverUrl = $rootScope.serverUrl;

            $scope.elmts = [];
            $scope.elmtIndex = 0;

            $scope.styleList = [];
            $scope.scaleFactor = 1;

            $scope.loadedPieces = 0;

            $scope.numCorrect = 0;
            $scope.ready = false;
            //console.log($scope.data);
            //console.log($scope.data.interactive.puzzle.background);
            //console.log($scope.data.interactive.puzzle.pieces[0].image);
            //console.log($scope.data.interactive.puzzle.pieces[0].coords);
            
            $timeout(function(){

                // set onload events on puzzle pieces
                $scope.allMissingPieces = document.getElementsByClassName("puzzle-piece");

                //$scope.setScale(); // hack
                $scope.initPuzzle();

            });


            window.onresize = function () {
                $scope.ready = false;
                $scope.initPuzzle();
            }

            $scope.initPuzzle = function()
            {
                var img = new Image();
                    img.onload = function() {
                        $scope.imagesize = {
                            width:this.width,
                            height:this.height
                        };
                        $scope.landscape = (this.width*.7 > this.height);
                        console.log("LANDSCAPE",$scope.landscape);
                        $scope.scale = bgHeight;

                        var el = document.getElementsByClassName("puzzle-piece");


                        $scope.container = angular.element(document.getElementById('posterimage'))[0];
                        //console.log( angular.element(document.getElementById('posterimage')));

                        var bgHeight = ($scope.landscape) ? $scope.container.offsetWidth / this.width : $scope.container.offsetHeight / this.height;
                        $scope.scale = bgHeight;
                        
                        $scope.actualHeight = ($scope.landscape) ? $scope.container.offsetWidth * this.height / this.width : $scope.container.offsetHeight * (this.width / this.height);
                        //console.log($scope.data.interactive.puzzle);
                        $scope.totalwidth = 0;
                        for(let i = 0; i < $scope.data.interactive.puzzle.pieces.length; i++)
                        {
                            $scope.styleList[i] = {};
                            if($scope.landscape){
                                $scope.styleList[i].width = ($scope.data.interactive.puzzle.pieces[i].coords.w*$scope.container.offsetWidth)+3.5 + "px";
                                $scope.styleList[i].height = ($scope.data.interactive.puzzle.pieces[i].coords.h*$scope.actualHeight)+3.5  + "px";
                            }
                            else{
                                console.log($scope.actualHeight,$scope.container.offsetHeight);
                                $scope.styleList[i].width = ($scope.data.interactive.puzzle.pieces[i].coords.w*$scope.actualHeight)+3.5 + "px";
                                $scope.styleList[i].height = ($scope.data.interactive.puzzle.pieces[i].coords.h*$scope.container.offsetHeight)+3.5  + "px";
       
                            }
                            var l =  $scope.landscape ? $scope.container.offsetWidth*$scope.data.interactive.puzzle.pieces[i].coords.x : $scope.actualHeight * $scope.data.interactive.puzzle.pieces[i].coords.x + (($scope.container.offsetWidth - (this.width*$scope.scale))/2);
                            var t =  $scope.landscape ? $scope.actualHeight*$scope.data.interactive.puzzle.pieces[i].coords.y : $scope.container.offsetHeight*$scope.data.interactive.puzzle.pieces[i].coords.y;
                            $scope.data.interactive.puzzle.pieces[i].solution = {
                                x:l,
                                y:t
                            };
                            //console.log("WDINOW",$window);
                            $scope.data.interactive.puzzle.pieces[i].home = {
                                x:$scope.totalwidth,
                                y: $scope.landscape ? $scope.actualHeight+($window.innerHeight*0.015) : $scope.container.offsetHeight + ($window.innerHeight*0.015)
                            };
                            $scope.totalwidth += $scope.landscape ? ($scope.data.interactive.puzzle.pieces[i].coords.w*$scope.container.offsetWidth)+($window.innerHeight*0.015) : ($scope.data.interactive.puzzle.pieces[i].coords.w*$scope.actualHeight) +($window.innerHeight*0.015);
                            
                            $scope.styleList[i].transform ="translate(" + $scope.data.interactive.puzzle.pieces[i].home.x + "px, " + $scope.data.interactive.puzzle.pieces[i].home.y + "px)";// scale(" + $scope.scale + ")";
                            $scope.$apply();
                        }
                        $scope.ready = true;
                        $scope.$apply();
                        //console.log("READY");
                    }
                img.src = $scope.serverUrl + $scope.data.interactive.puzzle.background.image; 
            }
            $scope.pzzl = document.getElementById("posterimage");
            $scope.pzzl.onload=function(){
                //console.log("LOADED");
            }

            $scope.onPieceStart = function(event)
            {

                // console.log("onPieceStart");

                // set element
                let elmt = event.element[0];
                if (elmt.isCorrect == undefined) elmt.isCorrect = false;

                // set index
                $scope.elmtIndex = parseInt(elmt.id);
                // add elmt to elmts at index
                $scope.elmts[$scope.elmtIndex] = elmt;
                
            }
             
            $scope.onPieceMove = function(event)
            {
                console.log("onPieceMove");

                if ($scope.elmts[$scope.elmtIndex].isCorrect == true) return;

                let l = event.center.x - event.target.parentElement.getBoundingClientRect().left - event.target.width/2;
                let t = event.center.y - event.target.parentElement.getBoundingClientRect().top - event.target.height/2;
                // let l = event.center.x - event.target.parentElement.getBoundingClientRect().x - event.target.width/2;
                // let t = event.center.y - event.target.parentElement.getBoundingClientRect().y - event.target.height/2;
                
                console.log(l + " - " + t);

                $scope.styleList[$scope.elmtIndex].transform ="translate(" + l + "px, " + t + "px)";// scale(" + $scope.scale + ")";// (event.center.x - event.target.offsetLeft - (event.target.clientWidth/2)) + 'px';

            }

            $scope.onPieceEnd = function(event,piece,idx)
            {
                // console.log("onPieceEnd");

                console.log($scope.imagesize);
                if ($scope.elmts[$scope.elmtIndex].isCorrect == true) return;

                let l = event.center.x - event.target.parentElement.getBoundingClientRect().left - event.target.width/2;
                let t = event.center.y - event.target.parentElement.getBoundingClientRect().top - event.target.height/2;

                if(isSimilar(l,piece.solution.x,$scope.imagesize.width) && isSimilar(t,piece.solution.y,$scope.imagesize.height)){
                    //console.log("YES");
                    $scope.styleList[idx].transform = "translate(" + piece.solution.x + "px, " + piece.solution.y + "px)"; 
                    $scope.styleList[idx].transition = "transform 50ms ease-in-out";
                    $timeout(function(){
                        $scope.styleList[idx].transition = "transform 0s ease-in-out";
                    },50);
                }
                else{
                    $scope.styleList[idx].transform ="translate(" + piece.home.x + "px, " + piece.home.y + "px)";
                    $scope.styleList[idx].transition = "transform 150ms ease-in-out";
                    $timeout(function(){
                        $scope.styleList[idx].transition = "transform 0s ease-in-out";
                    },150);
                    //console.log("NO");
                }
                //console.log("onPieceEnd",event);
            }
            Number.prototype.clamp = function(min, max) {
                return Math.min(Math.max(this, min), max);
            };
            function isSimilar(num1,num2,total){
                var perc1 = num1/total*100;
                var perc2 = num2/total*100;
                console.log(perc1,perc2);
                if(perc1+10 > perc2 && perc1-10 < perc2){
                    return true;
                }
                else{
                    return false;
                }
            }
        }
    };
});
