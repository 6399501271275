app.directive('pageVideo', function () {
    return {
        templateUrl: '/views/exhibit/interactives/page_video.html',
        replace : true,
        scope:{
            "data":"=data"
        },
        controller: function ($scope,$rootScope,Analytics) { $scope.serverUrl = $rootScope.serverUrl;
             // VIDEO
            $scope.videourl = "";
            $scope.videoOverlayVisible = false;
            $scope.toggleVideoOverlay = function(e)
            {
                if(!$scope.overlayVisible){
                    Analytics.trackEvent('video', 'play', $scope.videourl);
                }
                $scope.videourl = $rootScope.trustSrc($rootScope.serverUrl + $scope.data.interactive.videourl);
                console.log("toggleVideoOverlay",$scope.videourl);
                $scope.videoOverlayVisible = !$scope.videoOverlayVisible;
                var element = document.getElementById("player");
                var videoElement = angular.element(element);
                if($scope.videoOverlayVisible){
                    videoElement[0].play();
                }
                else{
                    videoElement[0].pause();
                }
                // if($scope.videoOverlayVisible)
                // {
                //     $scope.previousVisible = $scope.nextVisible = false;
                // }
                // else
                // {
                //     $scope.$parent.updateinteractive();
                // }
            }
        }
    };
});
