app.service('KMService', function ($route,$rootScope,$location,Api) {

	this.backLevel = "";
	this.selectedCollection = 0;

	this.accessibility = {
		isHighContrast:false,
		fontSize:1,
		fontSizes:[
			"Small-Font",
			"Medium-Font",
			"Large-Font"
		]
	};
	$rootScope.accessibility = this.accessibility;

	this.slugify = (input) => {
        var slug = input.toLowerCase().trim();
        slug = slug.replace(/[^a-z0-9\s-]/g, ' ');
        slug = slug.replace(/[\s-]+/g, '-');
        return slug;
    }

	this.logout = function()
	{
		console.log("Logout");
		Api.Get("auth/logout",function(res){
			delete $rootScope.user;
            $location.path("/");
        },function(error){
			delete $rootScope.user;
			$location.path("/");
		});
	};

	this.goBack = function(back)
    {
		console.log($location.path());
        console.log("Back");
		// window.location = "#/" + back;
		$location.path("/" + back);
	};
	
	this.setNextFontSize = function()
	{
		
		this.accessibility.fontSize++;
		if (this.accessibility.fontSize > 3)
		{
			this.accessibility.fontSize = 1;
		}
	}

	this.toggleHighContrast = function()
	{
		this.accessibility.isHighContrast = !this.accessibility.isHighContrast;
		console.log("toggle contrast: set to " + $rootScope.accessibility.isHighContrast);
	}

});