app.directive('pageEnd', function () {
    return {
        templateUrl: '/views/exhibit/interactives/page_end.html',
        replace : true,
        scope:{
            "data":"=data"
        },
        controller: function ($scope,$rootScope) { $scope.serverUrl = $rootScope.serverUrl;
            
        }
    };
});
