app.directive('zoomModal', function() {
    return {
      restrict: 'E',
      scope: {
        open: '='
      },
      replace: true, // Replace with the template below
      transclude: true, // we want to insert custom content inside the directive
      link: function(scope, element, attrs) {
        scope.image = "";
        scope.show = false;

        scope.open = function(url, allowed){
          if (allowed==true || allowed == undefined){
            scope.image = url;
            scope.show = true;
          }
        }
        scope.hideModal = function() {
          scope.show = false;
        };
      },
      templateUrl: '/views/exhibit/gui/zoom_modal.html',
    };
  });