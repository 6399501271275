app.directive('weblink', function () {
    return {
        templateUrl: '/views/exhibit/gui/weblink.html',
        replace : true,
        scope:{
            "data":"=data"
        },
        controller: function ($scope,$rootScope,$window,Analytics) {
            // SHARED
            $scope.launchWeblink = function(url)
            {
                Analytics.trackEvent('external', url);
                $window.open( url );
            }
        }
    };
});
