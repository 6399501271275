app.directive('pageMemory', function () {
    return {
        templateUrl: '/views/exhibit/interactives/page_memory.html',
        replace : true,
        scope:{
            "data":"=data"
        },
        controller: function ($scope,$rootScope,$timeout) { $scope.serverUrl = $rootScope.serverUrl;
            // $scope.setupGrid();
            $scope.setupGrid = function(){
                $scope.memoryGrid = new Array();
                var i = 0;
                for(var item in $scope.data.interactive.images){
                    $scope.memoryGrid.push({
                        'id':i,
                        'answer':typeof $scope.data.interactive.images[item].answer == 'undefined' ||  $scope.data.interactive.images[item].answer == false ? false : true,
                        'src':$scope.data.interactive.images[item].image,
                        'alt':$scope.data.interactive.images[item].alttext
                    });
                    i++;
                }
                // var string = JSON.stringify($scope.memoryGrid);
            
                $scope.memoryGrid = duplicate($scope.memoryGrid);//.concat(JSON.parse(string));
                $scope.memoryGrid = shuffle($scope.memoryGrid);
                console.log("memoryGrid",$scope.memoryGrid);
            };

            var openArr = [];
            function openTimeout(){
                console.log("time to close");
                for(var i = 0; i < openArr.length; i++){
                    openArr[i].status = "closed";
                }
                openArr = [];
                $scope.$apply();
                
            }
            var timer;
            $scope.open = function(card){
                console.log("Opened card");
                if(card.status != 'correct' && card.status != 'open' && card.status != 'hide'){
                    console.log("card is prob incorrect");
                    if(openArr.length < 2){
                        if(openArr.length < 1){
                            card.status = "open";
                            openArr.push(card);
                            var length = 4000;
                            if(card.answer == true){
                                console.log("its a match");
                                length = 2000;
                            }
                            timer = setTimeout(openTimeout,length);
                        }
                        else{
                            if(openArr[0].id == card.id){
                                card.status = "correct";
                                openArr[0].status = "correct";
                                openArr.push(card)
                                $scope.toSolve--;
                                clearTimeout(timer);
                                $timeout(function(){
                                    card.status = "hide";
                                    openArr[0].status = "hide";
                                    openArr = [];
                                    console.log($scope.toSolve);
                                    if($scope.toSolve < 0){
                                        finishGame();
                                    }
                                },1500);
                            }
                            else{
                                card.status = "open";
                                openArr.push(card);
                                clearTimeout(timer);
                                var length = 2000;
                                timer = setTimeout(openTimeout,length);
                            }
                        }
                    }
                }
            }
            function finishGame(){
                console.log("finishGame",$scope.memoryGrid);
                for(var i = 0; i < $scope.memoryGrid.length; i++){
                    if($scope.memoryGrid[i].answer == true){
                        console.log('opened',i);
                        $scope.memoryGrid[i].status = "open";
                    }

                }
                $scope.$apply();
            }
            function duplicate(array){
                $scope.answerAmount=0;
                var returnArray = [];
                for(var i = 0; i < array.length; i++){
                    returnArray.push(angular.copy(array[i]));
                    if(!array[i].answer){
                        returnArray.push(angular.copy(array[i]));
                    }
                    else{
                        $scope.answerAmount++;
                    }
                }
                $scope.toSolve = 10-$scope.answerAmount;
                return returnArray;
            }
            function shuffle(array) {
            var currentIndex = array.length, temporaryValue, randomIndex;

            // While there remain elements to shuffle...
            while (0 !== currentIndex) {

                // Pick a remaining element...
                randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex -= 1;

                // And swap it with the current element.
                temporaryValue = array[currentIndex];
                array[currentIndex] = array[randomIndex];
                array[randomIndex] = temporaryValue;
            }

            return array;
            }
        }
    };
});
