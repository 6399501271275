app.controller('OverviewCtrl', function ($scope,$rootScope, KMService, Api, $location,$sce,$timeout) {
    
    $scope.kmService = KMService;
    // temp hard coded
    $scope.filters =[
        {
            label:"Primair Onderwijs",
            key:"basis",
            // value:false
        },
        {
            label:"Secundair Onderwijs",
            key:"middel",
            // value:false
        },
        {
            label:"Speciaal Onderwijs",
            key:"speciaal",
            // value:false
        },
        {
            label:"Ouderen",
            key:"oud",
            // value:false
        },
        {
            label:"Gebarentaal",
            key:"gebaren",
            // value:false
        },
        {
            label:"International",
            key:"internationaal",
            // value:false
        }
    ];
    $scope.selectedFilter;

    $scope.selectedCalendar;


    
    Api.Get("collections/list-all", function (res) {
        $scope.allCals = res.data;
        console.log("cals: " , $scope.allCals);
    },function(error){
        console.log("List-all error " + error);
    });

    $scope.setFilter = function(filter)
    {
        
        // (re)set filter
        if ($scope.selectedFilter == filter)
        {
            $scope.selectedFilter = undefined;
        }
        else
        {
            $scope.selectedFilter = filter;
        }

    }

    $scope.selectCalendar = function(calendar)
    {
        $scope.selectedCalendar = calendar; 
        if ($scope.selectedCalendar)
        {
            console.log("Cal selected: " + $scope.selectedCalendar.name);
        }
    }

    $scope.mailto = function()
    {
        window.location.href = "mailto:"+$scope.selectedCalendar.contact+"?subject=Inlogcode kalender '" + $scope.selectedCalendar.name + "'&body=" + $scope.selectedCalendar.name;
    }

    $scope.openCalendar = function()
    {
        console.log("Open calendar by id " + $scope.selectedCalendar._id);
        console.log($scope.selectedCalendar);
        $rootScope.free = true;
        const collectionID = $scope.selectedCalendar._id;
        const collectionName = $scope.kmService.slugify($scope.selectedCalendar.name);
        $location.path("/calendars/" + collectionID + "/" + collectionName );
    }

    $scope.goHome = function()
    {
        $location.path("/");
    }

    
});