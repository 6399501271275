(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/index.html',
    '<!doctype html>\n' +
    '<html lang="nl" data-framework="angularjs" ng-app="scheurkalender" ng-controller="mainCtrl">\n' +
    '	\n' +
    '	<head>\n' +
    '		<base href="/">\n' +
    '		<meta charset="utf-8">\n' +
    '		<meta http-equiv="x-ua-compatible" content="IE=9">\n' +
    '		<meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
    '		<meta property="og:title" content="Digitale Scheurkalender" />\n' +
    '		<meta property="og:description" content="De avontuurlijke cultuursnack voor in de klas" />\n' +
    '		<title ng-bind="title">Digitale Scheurkalender</title>\n' +
    '		<meta name="mobile-web-app-capable" content="yes">\n' +
    '		<meta name="apple-mobile-web-app-capable" content="yes">\n' +
    '\n' +
    '		<script src=\'https://www.youtube.com/player_api\'></script>\n' +
    '		<link rel="stylesheet" href="/css/app.css">\n' +
    '	</head>	\n' +
    '	<!--[if IE]>\n' +
    '	<body style="background-color:#009EE2; color:white; font-family:arial; text-align:center;">\n' +
    '		<div style="width:700px; position:fixed; left:calc(50% - 350px);">\n' +
    '			<h1>Helaas</h1>\n' +
    '			<h3>Helaas wordt Internet Explorer niet ondersteund, voor de beste gebruikerservaring raden wij Google Chrome aan.</h3>\n' +
    '			<h3>Chrome kan je via <a style="color:white;" href="https://www.google.com/chrome/browser/desktop/">deze</a> link gratis downloaden. Mocht dit niet mogelijk zijn raden wij aan om Edge browser te gebruiken, excuses voor het ongemak.</h3>\n' +
    '			\n' +
    '		</div>\n' +
    '	</body>\n' +
    '	<![endif]-->\n' +
    '	<![if !IE]>\n' +
    '	<body>\n' +
    '		<div ng-class="accessibility.isHighContrast ? \'high-contrast\' : \'\'">\n' +
    '			<ng-view />\n' +
    '		</div>\n' +
    '		\n' +
    '		<script src="/js/bundle.js"></script>\n' +
    '	</body>\n' +
    '    <![endif]>\n' +
    '    <script type="text/javascript">\n' +
    '        (function (w) {\n' +
    '          var s = document.createElement(\'script\');\n' +
    '          s.src = \'https://survey.survicate.com/workspaces/79a2ff3b3b7d6d2f14f6f9eaacda5eb6/web_surveys.js\';\n' +
    '          s.async = true;\n' +
    '          var e = document.getElementsByTagName(\'script\')[0];\n' +
    '          e.parentNode.insertBefore(s, e);\n' +
    '        })(window);\n' +
    '    </script>\n' +
    '</html>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/calendars.html',
    '<div class="scheurkalender calendar-details" ng-class="accessibility.fontSizes[accessibility.fontSize-1]">\n' +
    '\n' +
    '    <menu-bar></menu-bar>\n' +
    '    <div class="portrait-force">\n' +
    '        <div class="center">\n' +
    '            <img src="/assets/images/rotating.svg"/>\n' +
    '            <h3>\n' +
    '                Draai het device een kwartslag om de Scheurkalender te bekijken.\n' +
    '            </h3>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="calendar-grid">\n' +
    '        <button class="nav-arrow" id="previous" ng-hide="currentPagination == 0" ng-click="currentPagination=currentPagination-1">\n' +
    '        </button>\n' +
    '        <div class="grid-container">\n' +
    '            <calendar-small posterurl="{{calendar.preview.image}}" title="{{calendar.name}}" alttag="{{calendar.preview.alttxt}}" ng-repeat="calendar in calendars | startFrom:currentPagination*12 | limitTo:12 | orderBy:\'index\'" ng-click="calendarClicked(calendar)"></calendar-small>\n' +
    '        </div>\n' +
    '        <button class="nav-arrow" id="next" ng-hide="currentPagination >= (calendars.length/12)-1" ng-click="currentPagination=currentPagination+1">\n' +
    '        </button>\n' +
    '    </div>\n' +
    '    \n' +
    '    <div class="bottom-bar">\n' +
    '        <div class="left">\n' +
    '            <!--<a class="button" ng-click="kmService.goColofon()">COLOFON</a>-->\n' +
    '            <button class="colofon" ng-click="toggleColofon()">\n' +
    '                <img class="centered-image" src="assets/images/colofon_icon.png"></img>\n' +
    '                <h1>COLOFON</h1>\n' +
    '            </button>\n' +
    '            <!--<a ng-if="back != undefined" class="button" ng-click="kmService.goBack(back)">BACK</a>-->\n' +
    '        </div>\n' +
    '        <div class="main-content">\n' +
    '            <img class="centered-image" src="assets/images/logo_black.svg"></img>\n' +
    '            <h1 class="saying">{{collection.saying}}</h1>\n' +
    '        </div>\n' +
    '        <div ng-if="collection.partners.length > 0" class="right-block">\n' +
    '            <img img-orientation class="centered-image" ng-src="{{serverUrl + collection.partners[partner].image}}" alt="logo\'s partners"></img>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    \n' +
    '    <div class="colofon-overlay" ng-if="colofonVisible">\n' +
    '        <div class="medium-white" markdown="{{collection.colofon}}"></div>\n' +
    '        <button class="close-button" ng-click="toggleColofon()">SLUIT</button>\n' +
    '    </div>\n' +
    '\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/calendar_collections.html',
    '<div class="scheurkalender calendars" ng-class="accessibility.fontSizes[accessibility.fontSize-1]">\n' +
    '\n' +
    '    <menu-bar></menu-bar>\n' +
    '    <div class="portrait-force">\n' +
    '        <div class="center">\n' +
    '            <img src="/assets/images/rotating.svg"/>\n' +
    '            <h3>\n' +
    '                Draai het device een kwartslag om de Scheurkalender te bekijken.\n' +
    '            </h3>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="container">\n' +
    '        <!--<div class="carousel" data-flickity=\'{ "pageDots": false, "setGallerySize": false, "cellAlign": "center" }\'>-->\n' +
    '        <div class="carousel" id="collections">\n' +
    '            <calendar-large posterurl="{{collection.preview.image}}" title="{{collection.name}}" alttag="{{collection.preview.alttext}}" ng-repeat="collection in filteredColllections = (collections | filter:focus)"></calendar-large>\n' +
    '        </div>\n' +
    '        <div class="creator-label">\n' +
    '        <h4 class="medium">{{currentCollection.owner.full_name | uppercase}}</h4>\n' +
    '    </div>\n' +
    '    </div>\n' +
    '    <!--<div class="creator-label">\n' +
    '        <h4 class="medium">{{currentCollection.owner.full_name | uppercase}}</h4>\n' +
    '    </div>-->\n' +
    '    \n' +
    '    <div class="bottom-bar">\n' +
    '        <div class="text-container" ng-if="currentCollection">\n' +
    '            <div class="blue-large"><h2 class="medium" markdown="{{currentCollection.name | uppercase }}"></h2></div>\n' +
    '            <div class="description">\n' +
    '                <p markdown="{{currentCollection.description}}"></p>\n' +
    '            </div>\n' +
    '            <div class="blue-small"><h5 markdown="{{currentCollection.audience}}"></h5></div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/calendar_page.html',
    '<div class="scheurkalender  page" ng-class="accessibility.fontSizes[accessibility.fontSize-1]">\n' +
    '<!-- <div class="page"> -->\n' +
    '\n' +
    '    <menu-bar></menu-bar>\n' +
    '    <div class="portrait-force">\n' +
    '        <div class="center">\n' +
    '            <img src="/assets/images/rotating.svg"/>\n' +
    '            <h3>\n' +
    '                Draai het device een kwartslag om de Scheurkalender te bekijken.\n' +
    '            </h3>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <!-- {{"high contrast = " + kmService.accessibility.isHighContast}}<br>\n' +
    '    {{"font size = " + kmService.accessibility.fontSize}} -->\n' +
    '\n' +
    '    <div class="interactive">\n' +
    '        <!--{{filteredPages[current]}}-->\n' +
    '        \n' +
    '        <button class="nav-arrow" alt="previous slide" role="btn" aria-label="vorige oefening" id="previous" ng-show="previousVisible" ng-click="previousinteractive()"></button>\n' +
    '        \n' +
    '        <!--main-->\n' +
    '        <page-main ng-if="off && filteredPages[current].type===\'main\'" data="filteredPages[current]"></page-main>\n' +
    '        \n' +
    '        <!--COMPARE-->\n' +
    '        <page-compare ng-if="off && filteredPages[current].type===\'compare\'" data="filteredPages[current]"></page-compare>\n' +
    '       \n' +
    '        <!--VIDEO-->\n' +
    '        <page-video ng-if="off && filteredPages[current].type===\'video\'" data="filteredPages[current]"></page-video>\n' +
    '\n' +
    '        <!--YOUTUBE-->\n' +
    '        <page-youtube ng-if="off && filteredPages[current].type===\'youtube\'" data="filteredPages[current]"></page-youtube>\n' +
    '\n' +
    '        <!--WATCH (magnifier)-->\n' +
    '        <page-watch ng-if="off && filteredPages[current].type===\'watch\'" data="filteredPages[current]"></page-watch>\n' +
    '\n' +
    '        <!--SLIDESHOW-->\n' +
    '        <page-slideshow ng-if="off && filteredPages[current].type===\'slideshow\'" data="filteredPages[current]"></page-slideshow>\n' +
    '\n' +
    '        <!--YOUTUBE SLIDESHOW-->\n' +
    '        <page-youtubeslideshow ng-if="off && filteredPages[current].type===\'youtubeslideshow\'" data="filteredPages[current]"></page-youtubeslideshow>\n' +
    '\n' +
    '        <!--VIDEOSLIDESHOW-->\n' +
    '        <page-video-slideshow ng-if="off && filteredPages[current].type===\'videoslideshow\'" data="filteredPages[current]"></page-video-slideshow>\n' +
    '\n' +
    '        <!--DEEPEN-->\n' +
    '        <page-deepen ng-if="off && filteredPages[current].type===\'deepen\'" data="filteredPages[current]"></page-deepen>\n' +
    '\n' +
    '        <!--COLORING-->\n' +
    '        <page-coloring ng-if="off && filteredPages[current].type===\'coloring\'" data="filteredPages[current]"></page-coloring>\n' +
    '\n' +
    '        <!--MEMORY-->\n' +
    '        <page-memory ng-if="off && filteredPages[current].type===\'memory\'" data="filteredPages[current]"></page-memory>\n' +
    '\n' +
    '        <!--QA-->\n' +
    '        <page-qa ng-if="off && filteredPages[current].type===\'qa\'" data="filteredPages[current]"></page-qa>\n' +
    '\n' +
    '        <!--ZOOM-->\n' +
    '        <page-zoom ng-if="off && filteredPages[current].type===\'zoom\'" data="filteredPages[current]"></page-zoom>\n' +
    '\n' +
    '        <!--LISTEN-->\n' +
    '        <page-listen ng-if="off && filteredPages[current].type===\'listen\'" data="filteredPages[current]"></page-listen>\n' +
    '\n' +
    '        <!--QUIZ-->\n' +
    '        <page-quiz ng-if="off && filteredPages[current].type===\'quiz\'" data="filteredPages[current]"></page-quiz>\n' +
    '\n' +
    '        <!--POSITIONING-->\n' +
    '        <page-positioning ng-if="off && filteredPages[current].type===\'positioning\'" data="filteredPages[current]"></page-positioning>\n' +
    '        \n' +
    '        <!--PUZZLE-->\n' +
    '        <page-puzzle ng-if="off && filteredPages[current].type===\'puzzle\'" data="filteredPages[current]"></page-puzzle>\n' +
    '\n' +
    '        <!--END-->\n' +
    '        <page-end ng-if="off && filteredPages[current].type===\'end\'" data="filteredPages[current]"></page-end>\n' +
    '        \n' +
    '\n' +
    '        <!--PRELOAD-->\n' +
    '        \n' +
    '        <!--main-->\n' +
    '        <page-main ng-show="true==false" ng-if="off && filteredPages[current+1].type===\'main\'" data="filteredPages[current+1]"></page-main>\n' +
    '        \n' +
    '        <!--COMPARE-->\n' +
    '        <page-compare ng-show="true==false" ng-if="off && filteredPages[current+1].type===\'compare\'" data="filteredPages[current+1]"></page-compare>\n' +
    '       \n' +
    '        <!--VIDEO-->\n' +
    '        <page-video ng-show="true==false" ng-if="off && filteredPages[current+1].type===\'video\'" data="filteredPages[current+1]"></page-video>\n' +
    '\n' +
    '        <!--YOUTUBE-->\n' +
    '        <page-youtube ng-show="true==false" ng-if="off && filteredPages[current+1].type===\'youtube\'" data="filteredPages[current+1]"></page-youtube>\n' +
    '\n' +
    '        <!--WATCH (magnifier)-->\n' +
    '        <page-watch ng-show="true==false" ng-if="off && filteredPages[current+1].type===\'watch\'" data="filteredPages[current+1]"></page-watch>\n' +
    '\n' +
    '        <!--SLIDESHOW-->\n' +
    '        <page-slideshow ng-show="true==false" ng-if="off && filteredPages[current+1].type===\'slideshow\'" data="filteredPages[current+1]"></page-slideshow>\n' +
    '\n' +
    '        <!--YOUTUBE SLIDESHOW-->\n' +
    '        <page-youtubeslideshow ng-show="true==false" ng-if="off && filteredPages[current+1].type===\'youtubeslideshow\'" data="filteredPages[current+1]"></page-youtubeslideshow>\n' +
    '\n' +
    '        <!--DEEPEN-->\n' +
    '        <page-deepen ng-show="true==false" ng-if="off && filteredPages[current+1].type===\'deepen\'" data="filteredPages[current+1]"></page-deepen>\n' +
    '\n' +
    '        <!--COLORING-->\n' +
    '        <page-coloring ng-show="true==false" ng-if="off && filteredPages[current+1].type===\'coloring\'" data="filteredPages[current+1]"></page-coloring>\n' +
    '\n' +
    '        <!--MEMORY-->\n' +
    '        <page-memory ng-show="true==false" ng-if="off && filteredPages[current+1].type===\'memory\'" data="filteredPages[current+1]"></page-memory>\n' +
    '\n' +
    '        <!--QA-->\n' +
    '        <page-qa ng-show="true==false" ng-if="off && filteredPages[current+1].type===\'qa\'" data="filteredPages[current+1]"></page-qa>\n' +
    '\n' +
    '        <!--ZOOM-->\n' +
    '        <page-zoom  ng-show="true==false"ng-if="off && filteredPages[current+1].type===\'zoom\'" data="filteredPages[current+1]"></page-zoom>\n' +
    '\n' +
    '        <!--LISTEN-->\n' +
    '        <page-listen  ng-show="true==false"ng-if="off && filteredPages[current+1].type===\'listen\'" data="filteredPages[current+1]"></page-listen>\n' +
    '\n' +
    '        <!--QUIZ-->\n' +
    '        <page-quiz  ng-show="true==false"ng-if="off && filteredPages[current+1].type===\'quiz\'" data="filteredPages[current+1]"></page-quiz>\n' +
    '\n' +
    '        <!--POSITIONING-->\n' +
    '        <page-positioning ng-show="true==false" ng-if="off && filteredPages[current+1].type===\'positioning\'" data="filteredPages[current+1]"></page-positioning>\n' +
    '        \n' +
    '        <!--PUZZLE-->\n' +
    '        <page-puzzle ng-show="true==false" ng-if="off && filteredPages[current+1].type===\'puzzle\'" data="filteredPages[current+1]"></page-puzzle>\n' +
    '\n' +
    '        <!--END-->\n' +
    '        <page-end ng-show="true==false" ng-if="off && filteredPages[current+1].type===\'end\'" data="filteredPages[current+1]"></page-end>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '        \n' +
    '        <div ng-if="!off" class="watch">\n' +
    '            <div class="background-leaf">\n' +
    '             </div>\n' +
    '            <div class="bottom-bar">\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        \n' +
    '        <button class="nav-arrow" alt="next slide" id="next" role="btn" aria-label="volgende oefening" ng-show="nextVisible" ng-click="nextinteractive()"></button>\n' +
    '\n' +
    '\n' +
    '        \n' +
    '    </div>\n' +
    '\n' +
    '    <div class="page-dots">\n' +
    '        <div ng-repeat="page in filteredPages = (pages.pages | filter:focus | orderBy: \'index\')" ng-click="goPage($index)" ng-class="$index==(current) ? \'dot-selected\' : \'dot\'"></div>\n' +
    '    </div>\n' +
    '\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/login.html',
    '<div class="login">\n' +
    '\n' +
    '    <div class="scrollcontainer">\n' +
    '\n' +
    '        <div class="video" id="video">\n' +
    '            <!--<img class="temp-vid" src="assets/videos/video_home.png" alt="">-->\n' +
    '            <iframe id="parade" src="https://www.youtube-nocookie.com/embed/videoseries?list=PLLyf8e_C_OwajeTCQluhFyTbSKaDJ6tsf&amp;controls=0&amp;showinfo=0&autoplay=1&loop=1" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>\n' +
    '            <!-- <iframe id="parade" src="https://www.youtube.com/embed/m_VPfj3ot_w?autoplay=1&loop=1&playlist=m_VPfj3ot_w&showinfo=0&rel=0&autohide=1&controls=0&modestbranding=1" -->\n' +
    '                <!-- allowfullscreen frameborder="0"></iframe> -->\n' +
    '            <div class="centered">\n' +
    '\n' +
    '                <form ng-submit="login()" id="login">\n' +
    '                    <img src="assets/images/login_logo.svg"></img>\n' +
    '                    <span class="error scale-fade" ng-show="invalidSchool">{{invalidMsgSchool}}</span>\n' +
    '                    <input tabindex="0" alt="Wachtwoord" ng-class="invalid ? \'err\' : \'\'" ng-change="invalidSchool = false" ng-model="user.name"\n' +
    '                        class="username" type="text" name="uname" placeholder="[ password ]" autofocus>\n' +
    '                    <!--<input tabindex="0" ng-class="invalid ? \'err\' : \'\'" ng-change="invalid = false" ng-model="user.password" class="username" type="password" name="password" placeholder="[ password ]">-->\n' +
    '                    <input tabindex="0" class="submit" type="submit" value="LOGIN">\n' +
    '                </form>\n' +
    '                <h4>Scroll down</h4>\n' +
    '                <img class="arrow" src="assets/images/scroll_arrow.svg" alt="">\n' +
    '\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="introduction">\n' +
    '            <div class="nl">\n' +
    '                <h1>Hoe werkt het?</h1>\n' +
    '                <h3>\n' +
    '                    Een Digitale Scheurkalender is een verfrissende cultuursnack, voor iedereen die zich graag laat verrassen. De kalenders zijn ontwikkeld voor smartboard en tablet en gratis te gebruiken. Vanwege de bescherming van beeld- en auteursrecht is een password nodig. Dit wordt verstrekt door de specifieke ontwikkelaar (of: Hub). \n' +
    '                </h3>\n' +
    '            </div>\n' +
    '            <div class="en">\n' +
    '                <h1>How does it work?</h1>\n' +
    '                <h3>\n' +
    '                    A Digital Block Calendar is a refreshing culture-snack, for anyone who likes to be surprised. The calendars are developed for smartboards and tablets and can be used free of charge. Due to copyright protection, a unique password is required. This is provided by the specific developer (or: Hub).\n' +
    '                </h3>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="available">\n' +
    '\n' +
    '            <h1>Uitgelicht/featured</h1>\n' +
    '\n' +
    '            <div class="scrollcontainer">\n' +
    '\n' +
    '                <div class="grid-item" ng-repeat="cal in allCals | filter : {\'feature\' : true}">\n' +
    '                    <!-- <div class="grid-item" ng-repeat="cal in allCals | filter : {\'feature\' : true}"> -->\n' +
    '                    <button ng-if="cal.free" class="ontdek" ng-click="openFeaturedCalendar(cal)"><img src="assets/images/bt_ontdek_nu.png" alt="Klik hier en ontdek"></button>\n' +
    '                    <calendar-large posterurl="{{cal.preview.image}}" title=" {{cal.name}}" alttag=" {{cal.preview.alttext}}"></calendar-large>\n' +
    '                    <img class="lock" ng-if="cal.free" src="assets/images/lock_open.svg">\n' +
    '                    <img class="lock" ng-if="!cal.free" src="assets/images/lock_closed.svg">\n' +
    '                    <p class="calendar-name">{{cal.name}}</p>\n' +
    '                    <p class="description">{{cal.description}}</p>\n' +
    '                    <h3 ng-if="cal.contact && !cal.free" class="bold end">Password via <a href="mailto: {{cal.contact}}">{{cal.contact}}</a></h3>\n' +
    '                </div>\n' +
    '\n' +
    '            </div>\n' +
    '            \n' +
    '            <div class="overview-button-container">\n' +
    '                <button class="overview-button" alt="previous slide" ng-click="showOverview()">Bekijk alle kalenders/view all calendars</button>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="hub">\n' +
    '            <div class="nl">\n' +
    '                <h1>Hub worden?</h1>\n' +
    '                <h3>\n' +
    '                    Een Hub is een samenwerking tussen verschillende educatieve instellingen: zoals musea, archieven,\n' +
    '                    scholen en bemiddelaars. Hub’s ontwikkelen via dit platform hun eigen scheurkalender(s) en vormen\n' +
    '                    samen de Club: verantwoordelijk voor het functioneren van de website en de eindredactie.\n' +
    '                    <br><br>\n' +
    '                    Om Hub te worden, dien je een verzoek in bij de Club (via info@digitalescheurkalender.com).\n' +
    '                    <br><br>\n' +
    '                    Vermeld daarbij (in ieder geval) de samenwerkingspartners, het thema van de kalender en de\n' +
    '                    doelgroep.\n' +
    '                    <br>\n' +
    '                    De Club neemt vervolgens contact op, over de mogelijkheden en voorwaarden.\n' +
    '                </h3>\n' +
    '            </div>\n' +
    '            <div class="en">\n' +
    '                <h1>Be a Hub?</h1>\n' +
    '                <h3>\n' +
    '                    A Hub is a collaboration between various educational institution: such as museums, archives,\n' +
    '                    schools and mediators. Through this platform, Hubs develop their own block calendar(s) and together\n' +
    '                    form the Club: responsible for the operation of the website and the editing.\n' +
    '                    <br><br>\n' +
    '                    To become a Hub you submit a request to the Club (via info@digitalescheurkalender.com).\n' +
    '                    <br><br>\n' +
    '                    Please state (at least) the collaboration partners, the theme of the calendar and the target group.\n' +
    '                    <br>\n' +
    '                    The Club will then contact you to discuss the possibilities and conditions.\n' +
    '                </h3>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="hub-inlog">\n' +
    '            <h1>LOG IN HUB\'S</h1>\n' +
    '            <form ng-submit="loginHubs()">\n' +
    '                <span class="error scale-fade" ng-show="invalidHub">{{invalidMsgHub}}</span>\n' +
    '                <input tabindex="0" ng-class="invalid ? \'err\' : \'\'" ng-change="invalidHub = false" ng-model="hub.name"\n' +
    '                    class="username grey" type="text" name="uname" placeholder="[ username ]" autofocus>\n' +
    '                <input tabindex="0" ng-class="invalid ? \'err\' : \'\'" ng-change="invalidHub = false" ng-model="hub.password"\n' +
    '                    class="username grey" type="password" name="password" placeholder="[ password ]">\n' +
    '                <input tabindex="0" class="submit blue" type="submit" value="LOGIN">\n' +
    '            </form>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="footer">\n' +
    '\n' +
    '            <div class="container">\n' +
    '                <img src="assets/images/KMM_logo.svg" alt="logo Kröller Müller"></img>\n' +
    '                <div class="text-block">\n' +
    '                    <p>© Stichting Kröller-Müller Museum</p>\n' +
    '                    <a class="terms" href="assets/pdf/Algemene Voorwaarden en disclaimer.pdf" target="_blank">\n' +
    '                        <p class="bold">Algemene voorwaarden &amp; disclaimer</p>\n' +
    '                    </a>\n' +
    '                    <a class="terms" href="assets/pdf/Privacy statement Homepage.pdf" target="_blank">\n' +
    '                        <p class="bold">Privacy</p>\n' +
    '                    </a>\n' +
    '                </div>\n' +
    '                <div class="text-block">\n' +
    '                    <p>Basisontwerp en logo’s: <b>Rudolf Das, PLOT</b></p>\n' +
    '                    <br>\n' +
    '                    <p>Dit platform is mede mogelijk gemaakt door</p>\n' +
    '                    <a class="terms" href="https://www.museumeducatieprijs.nl" target="_blank">\n' +
    '                        <p class="bold">de Museumeducatie Prijs</p>\n' +
    '                    </a>\n' +
    '                </div>\n' +
    '                <div class="text-block">\n' +
    '                    <img src="assets/images/KTF_logo.svg" alt="logo Kiss the Frog" style="height:80%;"></img>\n' +
    '                    <div>\n' +
    '                        <p class="small-white">Ontwerp & technische realisatie:</p>\n' +
    '                        <a class="terms" href="http://www.kissthefrog.nl" target="_blank">\n' +
    '                            <p class="bold">KISS THE FROG</p>\n' +
    '                        </a>\n' +
    '                        <p>INTERACTIVE MEDIA & CREATIVE TECHNOLOGY</p>\n' +
    '                    </div>\n' +
    '\n' +
    '                </div>\n' +
    '            </div>\n' +
    '\n' +
    '        </div>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/overview.html',
    '<div class="overview">\n' +
    '\n' +
    '    <div class = "logo" ng-click="goHome()">\n' +
    '        <img src="/assets/images/login_logo_blue.svg" alt="logo scheurkalender"/>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class = "filters">\n' +
    '        <ul class="filter-menu">\n' +
    '            <li ng-repeat="filter in filters">\n' +
    '                <a ng-click="setFilter(filter)" ng-class="selectedFilter==filter?\'selected\':null">{{filter.label}}</a>\n' +
    '            </li>\n' +
    '        </ul>\n' +
    '    </div>\n' +
    '\n' +
    '    <!-- {{selectedFilter}} -->\n' +
    '\n' +
    '    <div class = "scrollcontainer">\n' +
    '       \n' +
    '        <div class="grid-item" ng-repeat="cal in allCals | filter : {\'tags\' : selectedFilter.key}">\n' +
    '            <calendar-large posterurl="{{cal.preview.image}}" title="{{cal.name}}" alttag="{{cal.preview.alttext}}" ng-click="selectCalendar(cal)"></calendar-large>\n' +
    '            <span class="calendar-name">{{cal.name}}</span>\n' +
    '            <img class="lock" ng-if="cal.free" src="assets/images/lock_open.svg">\n' +
    '            <img class="lock" ng-if="!cal.free" src="assets/images/lock_closed.svg">\n' +
    '        </div>\n' +
    '        \n' +
    '    </div>\n' +
    '\n' +
    '    <div class="calendar-detail" ng-if="selectedCalendar!=null">\n' +
    '        \n' +
    '        <div class="top">\n' +
    '            <button class="button close black" ng-click="selectCalendar(null)"><img ng-src="assets/images/close.svg"></button>\n' +
    '        </div>\n' +
    '        \n' +
    '        \n' +
    '        <div class="content">\n' +
    '            <div class="calendar-container">\n' +
    '                <calendar-large posterurl="{{selectedCalendar.preview.image}}" title="{{selectedCalendar.name}}" alttag="{{selectedCalendar.preview.alttext}}"></calendar-large>\n' +
    '                <img class="lock" ng-if="selectedCalendar.free" src="assets/images/lock_open.svg">\n' +
    '                <img class="lock" ng-if="!selectedCalendar.free" src="assets/images/lock_closed.svg">\n' +
    '            </div>\n' +
    '            \n' +
    '            <div class="text-container">\n' +
    '                <p class="title">{{selectedCalendar.name}}</p>\n' +
    '                <p class="filters">{{selectedCalendar.audience}}</p>\n' +
    '                <p class="description">{{selectedCalendar.description}}</p>\n' +
    '                <a class="button black" ng-if="selectedCalendar.free" ng-click="openCalendar()">START</a>\n' +
    '                <a class="button black" ng-if="!selectedCalendar.free && selectedCalendar.contact" ng-click="mailto()"><span class="code">Password via</span><span class="blue"> {{selectedCalendar.contact}}</span></a>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '        \n' +
    '        \n' +
    '    </div>\n' +
    '\n' +
    '</div>\n' +
    '\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/gui/calendar_large.html',
    '<button class="preview-large cell">\n' +
    '    <img img-orientation class="poster" ng-src="{{serverUrl + attributes.posterurl}}" alt="{{attributes.alttag}}"></img>\n' +
    '</button>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/gui/calendar_small.html',
    '<button ng-class="calendar.class" class="calendar-preview-small cell">\n' +
    '    <img img-orientation class="calendar-poster_small" ng-src="{{serverUrl + attributes.posterurl}}" alt="{{attributes.alttag}}"></img>\n' +
    '    <div class="funny-corner"></div>\n' +
    '    <div class="funny-backdrop"></div>\n' +
    '</button>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/gui/header.html',
    '<header class="menu-header">\n' +
    '    <div class="left">\n' +
    '            <!-- {{"high contrast = " + kmService.isHighContast}} -->\n' +
    '        <!--<a class="button" ng-click="kmService.goColofon()">COLOFON</a>-->\n' +
    '        <!--<a ng-if="back != undefined" class="button" ng-click="kmService.goBack(back)">BACK</a>-->\n' +
    '        <!-- <a class="button" ng-click="kmService.toggleHighContrast()">contrast</a>\n' +
    '        <a class="button" ng-click="kmService.setNextFontSize()">fontsize</a> -->\n' +
    '\n' +
    '        <button class="button image contrast" ng-click="kmService.toggleHighContrast()"><img alt="hoog contrast" ng-src="assets/images/contrast.svg"></button>\n' +
    '        <button class="button image font-size" ng-click="kmService.setNextFontSize()"><img alt="tekstgrootte" ng-src="assets/images/font_size{{accessibility.fontSize}}.svg"></button>\n' +
    '        \n' +
    '\n' +
    '    </div>\n' +
    '    <div class="right">\n' +
    '        <h5 ng-if="isCollectionPage">{{user.name}}</h5>\n' +
    '        <a ng-if="isCollectionPage" class="button" ng-click="kmService.logout()">LOGOUT</a>\n' +
    '        <!-- <a ng-if="!isCollectionPage" class="button close" ng-click="goBack()">X</a> -->\n' +
    '        <button ng-if="!isCollectionPage" class="button close" ng-click="goBack()"><img ng-src="assets/images/close.svg" alt="sluiten"></button>\n' +
    '        \n' +
    '    </div>\n' +
    '</header>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/gui/weblink.html',
    '<div class="action-container" ng-click="launchWeblink(data.weblink)">\n' +
    '    <img class="action-icon" src="assets/images/interactives/compare/weblink.svg"></img>\n' +
    '    <h2 class="medium">{{data.weblinkname}}  »</h2>\n' +
    '    <h2 class="small">{{data.duration}}</h2>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/gui/zoom_modal.html',
    '<div class="zoom-modal" ng-show=\'show\'>\n' +
    '        <div ng-click="hideModal()"></div>\n' +
    '        <!-- <button class="close-button" ng-click="hideModal()">X</button> -->\n' +
    '        <button class="button close" ng-click="hideModal()"><img ng-src="assets/images/close_white.svg" alt="sluiten"></button>\n' +
    '        <img ng-src="{{serverUrl + image}}"></img>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/interactives/page_blank.html',
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/interactives/page_coloring.html',
    '<div class="coloring">\n' +
    '\n' +
    '    <!--<img class="background" src="assets/images/interactive_bg.png"></img>-->\n' +
    '\n' +
    '    <div class="background-leaf">\n' +
    '        <img img-orientation crossorigin="" alt="{{data.interactive.image.alttext}}" class="posterimage" id="drawing" ng-src="{{serverUrl + data.interactive.image.image}}"></img>\n' +
    '        <canvas id="canvas_drawing"></canvas>\n' +
    '        \n' +
    '    </div>\n' +
    '\n' +
    '    <div class="bottom-bar">\n' +
    '\n' +
    '        <!--container left-->\n' +
    '        <div class="title-container">\n' +
    '            <h2 class="medium rotated" markdown="{{data.name}}"></h2>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container center-->\n' +
    '        <div class="description-container">\n' +
    '            <div class="description" ng-hide=\'data.description == ""\'>\n' +
    '                <h2 class="medium" markdown="{{data.description}}"></h2>\n' +
    '            </div>\n' +
    '            <div class="source" markdown="{{data.source}}" ng-hide=\'data.source == ""\'></div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container right-->\n' +
    '        <div class="action-container">\n' +
    '            <button class="color-bubble" ng-repeat="color in data.interactive.colors" ng-click="selectColor(color)" ng-style="{\'background-color\':color}"></button>\n' +
    '        </div>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/interactives/page_compare.html',
    '<div class="compare">\n' +
    '    \n' +
    '    <!--<img class="background" src="assets/images/interactive_bg.png"></img>-->\n' +
    '    \n' +
    '    <div class="background-leaf" ng-class="getCompareOrientationClass()"> <!--.crop-container-horizontal or .crop-container-vertical-->\n' +
    '        <div class="crop">\n' +
    '            <img img-orientation alt="{{data.interactive.images[0].alttext}}" ng-src="{{serverUrl + data.interactive.images[0].image}}" ng-click="open(serverUrl + data.interactive.images[0].image)"></img>\n' +
    '        </div>\n' +
    '\n' +
    '        <div class="crop">\n' +
    '            <img img-orientation alt="{{data.interactive.images[1].alttext}}" ng-src="{{serverUrl + data.interactive.images[1].image}}" ng-click="open(serverUrl + data.interactive.images[1].image)"></img>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    \n' +
    '    <div class="bottom-bar">\n' +
    '\n' +
    '         <!--container left-->\n' +
    '        <div class="title-container">\n' +
    '            <h2 class="medium rotated" markdown="{{data.name}}"></h2>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container center-->\n' +
    '        <div class="description-container">\n' +
    '            <div class="description" ng-hide=\'data.description == ""\'>\n' +
    '                <h2 class="medium" markdown="{{data.description}}"></h2>\n' +
    '            </div>\n' +
    '            <div class="source" markdown="{{data.source}}" ng-hide=\'data.source == ""\'></div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container right-->\n' +
    '        <weblink ng-if="data.interactive.external.weblink != undefined && data.interactive.external.weblink != \'\'" data="data.interactive.external"></weblink>\n' +
    '\n' +
    '    </div>\n' +
    '    <zoom-modal open="open"></zoom-modal>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/interactives/page_deepen.html',
    '<div class="deepen">\n' +
    '\n' +
    '    <!--<img class="background" src="assets/images/interactive_bg.png"></img>-->\n' +
    '    <div class="background-leaf">\n' +
    '        <!-- <img img-orientation class="posterimage" ng-src="{{serverUrl + data.interactive.image.image}}" ng-click="open(serverUrl + data.interactive.image.image)"></img> -->\n' +
    '        <img img-orientation class="posterimage" alt="{{altImage==true ? (data.interactive.altimage.alttext) : (data.interactive.image.alttext) }}" ng-src="{{altImage==true ? (serverUrl + data.interactive.altimage.image) : (serverUrl + data.interactive.image.image) }}" ng-click="altImage==true ? open(serverUrl + data.interactive.altimage.image) : open(serverUrl + data.interactive.image.image)" ></img>\n' +
    '        <button ng-if="data.interactive.altimage" class="button ng-scope alt-image" ng-click="toggleAltImage()">\n' +
    '            <img ng-if="!altImage" alt="alternatieve afbeelding" ng-src="assets/images/alt_image.svg"></img>\n' +
    '            <img ng-if="altImage" alt="alternatieve afbeelding" ng-src="assets/images/alt_image_active.svg"></img>\n' +
    '        </button>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="bottom-bar">\n' +
    '\n' +
    '        <!--container left-->\n' +
    '        <div class="title-container">\n' +
    '            <h2 class="medium rotated" markdown="{{data.name}}"></h2>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container center-->\n' +
    '        <div class="description-container">\n' +
    '            <div class="description" ng-hide=\'data.description == ""\'>\n' +
    '                <h2 class="medium" markdown="{{data.description}}"></h2>\n' +
    '            </div>\n' +
    '            <div class="source" markdown="{{data.source}}" ng-hide=\'data.source == ""\'></div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container right-->\n' +
    '        <div ng-if="data.interactive.wildweetje" class="action-container" ng-click="toggleOverlay($event)">\n' +
    '            <img class="action-icon weetje" src="assets/images/interactives/deepen/wilde_weetjes.svg"></img>\n' +
    '        </div>\n' +
    '        \n' +
    '        <h2 ng-if="!data.interactive.wildweetje" class="action-container large" ng-click="toggleOverlay($event)">{{data.interactive.buttonname}}  »</h2>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="overlay" ng-show="overlayVisible">\n' +
    '        <img ng-if="data.interactive.wildweetje" class="action-icon" src="assets/images/interactives/deepen/wilde_weetjes_white.svg"></img>\n' +
    '        <div class="medium-white" markdown="{{data.interactive.assignment}}"></div>\n' +
    '        <button class="close-button" ng-click="toggleOverlay($event)">SLUIT</button>\n' +
    '    </div>\n' +
    '\n' +
    '    <zoom-modal open="open"></zoom-modal>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/interactives/page_end.html',
    '<div class="end">\n' +
    '\n' +
    '    <div class="background-leaf" >\n' +
    '        <h1>{{data.end}}</h1>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="bottom-bar">\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/interactives/page_front.html',
    '<div class="main">\n' +
    '\n' +
    '    <div class="background-leaf" >\n' +
    '        <img img-orientation ng-src="{{serverUrl + data.preview.image}}" ng-click="open(serverUrl + data.preview.image)"></img>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="bottom-bar">\n' +
    '\n' +
    '        <!--container left-->\n' +
    '        <!--<div class="title-container">\n' +
    '        <h2 class="medium rotated">{{data.name}}</h2>\n' +
    '        </div>-->\n' +
    '        <!--container center-->\n' +
    '        <!--<div class="description-container">\n' +
    '            <div class="description">\n' +
    '                <h2 class="medium">{{data.title}}</h2>\n' +
    '            </div>\n' +
    '            <div class="source">{{data.year}}</div>\n' +
    '            <div class="source">{{data.period}}</div>\n' +
    '        </div>-->\n' +
    '        <div class="text-container">\n' +
    '            <div class="blue-large"><h2 class="medium" markdown="{{data.name | uppercase}}"></h2></div>\n' +
    '            <div class="description">\n' +
    '                <p markdown="{{data.period}}"></p>\n' +
    '            </div>\n' +
    '            <div class="blue-small"><h5 markdown="{{data.title}}"></h5></div>\n' +
    '            <div class="description">\n' +
    '                <p markdown="{{data.year}}"></p>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <zoom-modal open="open"></zoom-modal>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/interactives/page_listen.html',
    '<div class="listen">\n' +
    '\n' +
    '    <div class="background-leaf" >\n' +
    '        <!-- <img img-orientation ng-src="{{serverUrl + data.interactive.image.image}}" ng-click="open(serverUrl + data.interactive.image.image)" ></img> -->\n' +
    '        <img img-orientation alt="{{altImage==true ? (data.interactive.altimage.alttext) : (data.interactive.image.alttext) }}" ng-src="{{altImage==true ? (serverUrl + data.interactive.altimage.image) : (serverUrl + data.interactive.image.image) }}" ng-click="altImage==true ? open(serverUrl + data.interactive.altimage.image) : open(serverUrl + data.interactive.image.image)" ></img>\n' +
    '        <button ng-if="data.interactive.altimage" class="button ng-scope alt-image" ng-click="toggleAltImage()">\n' +
    '            <img ng-if="!altImage" alt="alternatieve afbeelding" ng-src="assets/images/alt_image.svg"></img>\n' +
    '            <img ng-if="altImage" alt="alternatieve afbeelding" ng-src="assets/images/alt_image_active.svg"></img>\n' +
    '        </button>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '    <div class="bottom-bar">\n' +
    '\n' +
    '        <!--container left-->\n' +
    '        <div class="title-container">\n' +
    '        <h2 class="medium rotated" markdown="{{data.name}}"></h2>\n' +
    '        </div>\n' +
    '        <!--container center-->\n' +
    '        <div class="description-container">\n' +
    '            <div class="description" ng-hide=\'data.description == ""\'>\n' +
    '                <h2 class="medium" markdown="{{data.description}}"></h2>\n' +
    '            </div>\n' +
    '            <div class="source" markdown="{{data.source}}" ng-hide=\'data.source == ""\'></div>\n' +
    '            <div class="audio">\n' +
    '                <button ng-click="play($index)" ng-repeat="audio in data.interactive.audio track by $index" ng-class="{\'active\' : $index == lastTrackID, \'single\' : data.interactive.audio.length==1}">{{$index+1}}</button>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <audio id="audio-player" controls ng-src="{{currentTrack.track}}"></audio>\n' +
    '    </div>\n' +
    '    <zoom-modal open="open"></zoom-modal>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/interactives/page_memory.html',
    '<div class="memory" ng-init="setupGrid()">\n' +
    '\n' +
    '    <div class="calendar-grid">\n' +
    '        <div class="grid-container">\n' +
    '            <div class="cell-container" ng-repeat="card in memoryGrid track by $index">\n' +
    '                <div ng-init="card.status = \'closed\';" class="cell" ng-click="open(card)" ng-class="card.status">\n' +
    '                    <figure class="front" alt="{{card.alt}}" ></figure>\n' +
    '                    <figure class="back" ng-style="{\'background-image\':\'url(\'+ serverUrl + card.src +\')\'}"></figure>\n' +
    '                </div>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="bottom-bar">\n' +
    '\n' +
    '         <!--container left-->\n' +
    '        <div class="title-container">\n' +
    '            <h2 class="medium rotated" markdown="{{data.name}}"></h2>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container center-->\n' +
    '        <div class="description-container">\n' +
    '            <div class="description" ng-hide=\'data.description == ""\'>\n' +
    '                <h2 class="medium" markdown="{{data.description}}"></h2>\n' +
    '            </div>\n' +
    '            <div class="source" markdown="{{data.source}}" ng-hide=\'data.source == ""\'></div>\n' +
    '        </div>\n' +
    '\n' +
    '    </div>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/interactives/page_positioning.html',
    '<div class="positioning">\n' +
    '    <button class="carousel-nav left" ng-show="data.interactive.images.length > 1" ng-click="move(-1)"></button>\n' +
    '    <button class="carousel-nav right" ng-show="data.interactive.images.length > 1" ng-click="move(+1)"></button>\n' +
    '    \n' +
    '    <div class="carousel-wrapper" ng-init="current = 0;">\n' +
    '        <div class="background-leaf carousel" ng-class="getClass($index); " ng-repeat="image in data.interactive.images">\n' +
    '            <img img-orientation class="posterimage" alt="{{image.alttext}}" ng-src="{{serverUrl + image.image}}"></img>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <img img-orientation class="movable-object" alt="{{image.alttext}}" hm-panmove="onObjectMove($event)" ng-src="{{serverUrl + image.image}}" ng-style="draggedStyle"></img>\n' +
    '\n' +
    '    <div class="bottom-bar">\n' +
    '\n' +
    '         <!--container left-->\n' +
    '        <div class="title-container">\n' +
    '            <h2 class="medium rotated" markdown="{{data.name}}"></h2>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container center-->\n' +
    '        <div class="description-container">\n' +
    '            <div class="description" ng-hide=\'data.description == ""\'>\n' +
    '                <h2 class="medium" markdown="{{data.description}}"></h2>\n' +
    '            </div>\n' +
    '            <div class="source" markdown="{{data.source}}" ng-hide=\'data.source == ""\'></div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container right-->\n' +
    '        <weblink ng-if="data.interactive.external.weblink != undefined && data.interactive.external.weblink != \'\'" data="data.interactive.external"></weblink>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/interactives/page_puzzle.html',
    '<div class="puzzle">\n' +
    '    <!--<img class="background" src="assets/images/interactive_bg.png"></img>-->\n' +
    '    <div class="background-leaf">\n' +
    '        <div class="puzzle-container-outer" ng-class="{\'show-div\': ready, \'hide-div\': !ready}">\n' +
    '            <div class="puzzle-container">\n' +
    '\n' +
    '                <!--<div class="posterimage" id="posterimage" back-img="{{serverUrl + data.interactive.image}}">\n' +
    '                    <img class="puzzle-piece" id={{$index}} ng-style="styleList[$index]" hm-panstart="onPieceStart($event)" hm-panend="onPieceEnd($event)" hm-panmove="onPieceMove($event)" ng-src="{{serverUrl + piece.image}}" ng-repeat="piece in data.interactive.pieces" ></img>\n' +
    '                </div>-->\n' +
    '                <div class="posterimage" id="posterimage" back-img="{{serverUrl + data.interactive.puzzle.background.image}}">\n' +
    '                    <!--<img class="puzzle-piece" id={{$index}} ng-style="styleList[$index]" hm-panstart="onPieceStart($event)" hm-panend="onPieceEnd($event)" hm-panmove="onPieceMove($event)" ng-src="{{serverUrl + piece.image}}" ng-repeat="piece in data.interactive.pieces" ></img>-->\n' +
    '                    <img draggable="false" class="puzzle-piece" id={{$index}} ng-style="styleList[$index]" hm-panstart="onPieceStart($event)" hm-panend="onPieceEnd($event,piece,$index)"\n' +
    '                    hm-panmove="onPieceMove($event)" ng-src="{{serverUrl + piece.image}}" ng-repeat="piece in data.interactive.puzzle.pieces"></img>\n' +
    '                </div>\n' +
    '\n' +
    '\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '    </div>\n' +
    '    <div class="bottom-bar">\n' +
    '\n' +
    '        <!--container left-->\n' +
    '        <div class="title-container">\n' +
    '            <h2 class="medium rotated" markdown="{{data.name}}"></h2>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container center-->\n' +
    '        <div class="description-container">\n' +
    '            <div class="description" ng-hide=\'data.description == ""\'>\n' +
    '                <h2 class="medium" markdown="{{data.description}}"></h2>\n' +
    '            </div>\n' +
    '            <div class="source" markdown="{{data.source}}" ng-hide=\'data.source == ""\'></div>\n' +
    '        </div>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/interactives/page_qa.html',
    '<div class="qa">\n' +
    '\n' +
    '    <div class="container">\n' +
    '        <div ng-init="data.status = \'question\';" class="cell" ng-class="data.status">\n' +
    '                <figure class="front">\n' +
    '                    <!-- <img img-orientation class="posterimage" ng-src="{{serverUrl + data.interactive.question.image}}" ng-click="open(serverUrl + data.interactive.question.image)"></img> -->\n' +
    '                    <img img-orientation class="posterimage" alt="{{altQuestion==true ? (data.interactive.altquestion.alttext) : (data.interactive.question.alttext) }}" ng-src="{{altQuestion==true ? (serverUrl + data.interactive.altquestion.image) : (serverUrl + data.interactive.question.image) }}" ng-click="altQuestion==true ? open(serverUrl + data.interactive.altquestion.image) : open(serverUrl + data.interactive.question.image)" ></img>\n' +
    '                    <button ng-if="data.interactive.altquestion && data.status == \'question\'" class="button ng-scope alt-image" ng-click="toggleAltQuestion()">\n' +
    '                        <img ng-if="!altQuestion" alt="alternatieve afbeelding" ng-src="assets/images/alt_image.svg"></img>\n' +
    '                        <img ng-if="altQuestion" alt="alternatieve afbeelding" ng-src="assets/images/alt_image_active.svg"></img>\n' +
    '                    </button>\n' +
    '                </figure>\n' +
    '                <figure class="back">\n' +
    '                    <!-- <img img-orientation class="posterimage" ng-src="{{serverUrl + data.interactive.answer.image}}" ng-click="open(serverUrl + data.interactive.answer.image)"></img> -->\n' +
    '                    <img img-orientation class="posterimage" alt="{{altAnswer==true ? (data.interactive.altanswer.alttext) : (data.interactive.answer.alttext) }}" ng-src="{{altAnswer==true ? (serverUrl + data.interactive.altanswer.image) : (serverUrl + data.interactive.answer.image) }}" ng-click="altAnswer==true ? open(serverUrl + data.interactive.altanswer.image) : open(serverUrl + data.interactive.answer.image)" ></img>\n' +
    '                    <button ng-if="data.interactive.altanswer && data.status != \'question\'" class="button ng-scope alt-image" ng-click="toggleAltAnswer()">\n' +
    '                        <img ng-if="!altAnswer" alt="alternatieve afbeelding" ng-src="assets/images/alt_image.svg"></img>\n' +
    '                        <img ng-if="altAnswer" alt="alternatieve afbeelding" ng-src="assets/images/alt_image_active.svg"></img>\n' +
    '                    </button>\n' +
    '                </figure>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '   <div class="bottom-bar">\n' +
    '\n' +
    '        <!--container left-->\n' +
    '        <div class="title-container">\n' +
    '        <h2 class="medium rotated" markdown="{{data.name}}"></h2>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container center-->\n' +
    '        <div class="description-container">\n' +
    '            <div class="description">\n' +
    '                <h2 class="medium">{{(data.status == \'answer\') ? \'\' : data.description}}</h2>\n' +
    '                <h2 class="medium">{{(data.status == \'answer\') ? data.interactive.answertext : data.interactive.questiontext}}</h2>\n' +
    '            </div>\n' +
    '            <div class="source" markdown="{{data.source}}" ng-hide=\'data.source == ""\'></div>\n' +
    '        </div>\n' +
    '        \n' +
    '        <!--container right-->\n' +
    '        <div class="action-container" ng-click="data.status == \'question\' ? data.status = \'answer\' : data.status = \'question\'">\n' +
    '              <h2 ng-if="data.status == \'question\'" class="medium" markdown="{{data.interactive.answername}} »"></h2>\n' +
    '              <h2 ng-if="data.status == \'answer\'" class="medium" markdown="{{data.interactive.questionname}} »"></h2>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <zoom-modal open="open"></zoom-modal>\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/interactives/page_quiz.html',
    '<div class="quiz">\n' +
    '\n' +
    '    \n' +
    '    <div class="background-leaf">\n' +
    '        <div class="quiz-content">\n' +
    '            <div class="image-container" id="image-container">\n' +
    '                <!--<img img-orientation class="quizimage" ng-if="image.image!=undefined" ng-style="adjustedWidth" ng-src="{{serverUrl + image.image}}" ng-repeat="image in data.interactive.images" ng-click="onClick($index, image.info)"></img>-->\n' +
    '                <img img-orientation alt="{{image.image.alttext}}" class="quizimage" ng-style="adjustedDimensions" ng-src="{{serverUrl + image.image.image}}" ng-repeat="image in images" ng-click="onClick($index, image.info)"></img>\n' +
    '            </div>\n' +
    '            <div class="text-container">{{selectedText}}</div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="bottom-bar">\n' +
    '\n' +
    '         <!--container left-->\n' +
    '        <div class="title-container">\n' +
    '            <h2 class="medium rotated" markdown="{{data.name}}"></h2>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container center-->\n' +
    '        <div class="description-container">\n' +
    '            <div class="description" ng-hide=\'data.description == ""\'>\n' +
    '                <h2 class="medium" markdown="{{data.description}}"></h2>\n' +
    '            </div>\n' +
    '            <div class="source" markdown="{{data.source}}" ng-hide=\'data.source == ""\'></div>\n' +
    '        </div>\n' +
    '\n' +
    '        \n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/interactives/page_slideshow.html',
    '<div class="slideshow">\n' +
    '    <button class="carousel-nav left" role="btn" aria-label="draai links" ng-click="move(-1)"></button>\n' +
    '    <button class="carousel-nav right" role="btn" aria-label="draai rechts" ng-click="move(+1)"></button>\n' +
    '    \n' +
    '    <div class="carousel-wrapper" ng-init="current = 0;">\n' +
    '        <div class="background-leaf carousel" ng-class="getClass($index); " ng-repeat="image in data.interactive.images">\n' +
    '            <!-- <img img-orientation class="posterimage" ng-src="{{serverUrl + image.image.image}}" ng-click="open(serverUrl + image.image.image)"></img> -->\n' +
    '            <img img-orientation alt="{{altImage==true && getClass($index) == \'current\' ? (image.altimg.alttext) : (image.image.alttext) }}" ng-src="{{altImage==true && getClass($index) == \'current\' ? (serverUrl + image.altimg.image) : (serverUrl + image.image.image) }}" ng-click="altImage==true ? open(serverUrl + image.altimg.image, getClass($index) == \'current\') : open(serverUrl + image.image.image, getClass($index) == \'current\')" ></img>\n' +
    '            <button ng-if="image.altimg && getClass($index) == \'current\'" class="button ng-scope alt-image" ng-click="toggleAltImage()">\n' +
    '                <img ng-if="!altImage" alt="alternatieve afbeelding" ng-src="assets/images/alt_image.svg"></img>\n' +
    '                <img ng-if="altImage" alt="alternatieve afbeelding" ng-src="assets/images/alt_image_active.svg"></img>\n' +
    '            </button>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="bottom-bar">\n' +
    '\n' +
    '         <!--container left-->\n' +
    '        <div class="title-container">\n' +
    '            <h2 class="medium rotated" markdown="{{data.name}}"></h2>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container center-->\n' +
    '        <div class="description-container">\n' +
    '            <div class="description" ng-hide=\'data.description == ""\'>\n' +
    '                <h2 class="medium" markdown="{{data.description}}"></h2>\n' +
    '            </div>\n' +
    '            <div ng-if="data.interactive.images[current].info" class="source" markdown="{{data.interactive.images[current].info}}"></div>\n' +
    '            <div ng-if="!data.interactive.images[current].info" class="source" markdown="{{data.source}}"></div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container right-->\n' +
    '        <weblink ng-if="data.interactive.external.weblink != undefined && data.interactive.external.weblink != \'\'" data="data.interactive.external"></weblink>\n' +
    '\n' +
    '    </div>\n' +
    '    <zoom-modal open="open"></zoom-modal>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/interactives/page_video.html',
    '<div class="video">\n' +
    '\n' +
    '    <!--<img class="background" src="assets/images/interactive_bg.png"></img>-->\n' +
    '\n' +
    '    <div class="background-leaf">\n' +
    '        <img ng-show="!data.interactive.wildweetje" class="action-icon" src="assets/images/interactives/video/video.svg" ng-click="toggleVideoOverlay($event)"></img>\n' +
    '        <img img-orientation class="posterimage" alt="{{data.interactive.posterimage.alttext}}" ng-src="{{serverUrl + data.interactive.posterimage.image}}"></img>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="bottom-bar">\n' +
    '\n' +
    '         <!--container left-->\n' +
    '        <div class="title-container">\n' +
    '            <h2 class="medium rotated" markdown="{{data.name}}"></h2>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container center-->\n' +
    '        <div class="description-container">\n' +
    '            <div class="description" ng-hide=\'data.description == ""\'>\n' +
    '                <h2 class="medium" markdown="{{data.description}}"></h2>\n' +
    '            </div>\n' +
    '            <div class="source" markdown="{{data.source}}" ng-hide=\'data.source == ""\'></div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container right-->\n' +
    '        <div ng-if="!data.interactive.wildweetje" class="action-container" ng-click="toggleVideoOverlay($event)">\n' +
    '            <img class="action-icon" src="assets/images/interactives/video/video.svg"></img>\n' +
    '            <h2 class="medium" markdown="{{data.interactive.buttonname}}"> »</h2>\n' +
    '            <h2 class="small" markdown="{{data.interactive.duration}}"></h2>\n' +
    '        </div>\n' +
    '        <div ng-if="data.interactive.wildweetje" class="action-container" ng-click="toggleVideoOverlay($event)">\n' +
    '             <img class="action-icon weetje" src="assets/images/interactives/deepen/wilde_weetjes.svg"></img>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="video-overlay" ng-show="videoOverlayVisible">\n' +
    '        <div class="background" ng-click="toggleVideoOverlay($event)"></div>\n' +
    '        <video class="player" id="player" controls ng-src="{{videourl}}" autoplay>\n' +
    '            <a class="button">SLUIT</a>\n' +
    '        </video>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/interactives/page_videoslideshow.html',
    '<div class="slideshow">\n' +
    '    <button class="carousel-nav left" role="btn" aria-label="draai links" ng-click="move(-1)"></button>\n' +
    '    <button class="carousel-nav right" role="btn" aria-label="draai rechts" ng-click="move(+1)"></button>\n' +
    '    \n' +
    '    <div class="carousel-wrapper" ng-init="current = 0;">\n' +
    '        <div class="background-leaf carousel" ng-class="getClass($index); " ng-repeat="video in data.interactive.videos">\n' +
    '            <img class="action-icon" src="assets/images/interactives/video/video.svg"></img>\n' +
    '            <video ng-click="toggle($event)" class="player" id="player" ng-src="{{video.video}}" >\n' +
    '            </video>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="bottom-bar">\n' +
    '\n' +
    '         <!--container left-->\n' +
    '        <div class="title-container">\n' +
    '            <h2 class="medium rotated" markdown="{{data.name}}"></h2>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container center-->\n' +
    '        <div class="description-container">\n' +
    '            <div class="description" ng-hide=\'data.description == ""\'>\n' +
    '                <h2 class="medium" markdown="{{data.description}}"></h2>\n' +
    '            </div>\n' +
    '            <div ng-if="data.interactive.videos[current].info" class="source" markdown="{{data.interactive.videos[current].info}}"></div>\n' +
    '            <div ng-if="!data.interactive.videos[current].info" class="source" markdown="{{data.source}}"></div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container right-->\n' +
    '        <weblink ng-if="data.interactive.external.weblink != undefined && data.interactive.external.weblink != \'\'" data="data.interactive.external"></weblink>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/interactives/page_watch.html',
    '<div class="watch">\n' +
    '    \n' +
    '    <!--<img class="background" src="assets/images/interactive_bg.png"></img>-->\n' +
    '    \n' +
    '    <!-- Lets make a simple image magnifier -->\n' +
    '    <!--<div ng-if="data.interactive.magnify" class="event-space" id="eventSpace" ng-mouseleave="leaveMagnifier()" ng-mousemove="moveMagnifier($event)"></div> -->\n' +
    '    <div ng-if="data.interactive.magnify" class="event-space" id="eventSpace" hm-panend="leaveMagnifier()" hm-press="moveMagnifier($event)" hm-panmove="moveMagnifier($event)"></div> \n' +
    '    \n' +
    '    <!-- <div ng-if="data.interactive.magnify" class="magnify background-leaf" id="leaf" ng-init="initMagnifier()"> -->\n' +
    '    <div ng-if="data.interactive.magnify" class="magnify background-leaf" id="leaf">\n' +
    '        \n' +
    '        <!-- This is the small image -->\n' +
    '        <img img-orientation class="small" id="small" \n' +
    '        alt="{{altImage==true ? (data.interactive.altimage.alttext) : (data.interactive.image.alttext) }}"\n' +
    '        ng-src="{{altImage==true ? (serverUrl + data.interactive.altimage.image) : (serverUrl + data.interactive.image.image) }}" \n' +
    '        ng-click="altImage==true ? open(serverUrl + data.interactive.altimage.image) : open(serverUrl + data.interactive.image.image)"></img>\n' +
    '        \n' +
    '        <!-- This is the magnifying glass which will contain the original/large version -->\n' +
    '        <button ng-if="data.interactive.altimage" class="button ng-scope alt-image" ng-click="toggleAltImage()">\n' +
    '            <img ng-if="!altImage" alt="alternatieve afbeelding" ng-src="assets/images/alt_image.svg"></img>\n' +
    '            <img ng-if="altImage" alt="alternatieve afbeelding" ng-src="assets/images/alt_image_active.svg"></img>\n' +
    '        </button>\n' +
    '    </div>\n' +
    '    <!-- <div ng-if="data.interactive.magnify" class="large" id="mag" ng-style="{\'background-image\':\'url(\'+(serverUrl + data.interactive.image.image)+\')\'}"></div> -->\n' +
    '    <div ng-if="data.interactive.magnify" class="large" id="mag" \n' +
    '    ng-style="altImage==true ? ({\'background-image\':\'url(\'+(serverUrl + data.interactive.altimage.image)+\')\'}) : ({\'background-image\':\'url(\'+(serverUrl + data.interactive.image.image)+\')\'})"></div>\n' +
    '\n' +
    '    <img ng-if="data.interactive.magnify" class="mag-img" id="magImg" src="assets/images/interactives/watch/magnifier.png"></img>\n' +
    '\n' +
    '    <div ng-if="!data.interactive.magnify" class="magnify background-leaf">\n' +
    '        \n' +
    '        <!-- This is the small image -->\n' +
    '        <!-- <img img-orientation class="small" id="small" ng-src="{{serverUrl + data.interactive.image.image}}" ng-click="open(serverUrl + data.interactive.image.image)"></img> -->\n' +
    '        \n' +
    '        <img img-orientation class="small" \n' +
    '        alt="{{altImage==true ? (data.interactive.altimage.alttext) : (data.interactive.image.alttext) }}"\n' +
    '        ng-src="{{altImage==true ? (serverUrl + data.interactive.altimage.image) : (serverUrl + data.interactive.image.image) }}" \n' +
    '        ng-click="altImage==true ? open(serverUrl + data.interactive.altimage.image) : open(serverUrl + data.interactive.image.image)"></img>\n' +
    '\n' +
    '        <button ng-if="data.interactive.altimage" class="button ng-scope alt-image" ng-click="toggleAltImage()">\n' +
    '            <img ng-if="!altImage" alt="alternatieve afbeelding" ng-src="assets/images/alt_image.svg"></img>\n' +
    '            <img ng-if="altImage" alt="alternatieve afbeelding" ng-src="assets/images/alt_image_active.svg"></img>\n' +
    '        </button>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '    <div class="bottom-bar">\n' +
    '\n' +
    '     <!--container left-->\n' +
    '        <div class="title-container">\n' +
    '            <h2 class="medium rotated" markdown="{{data.name}}"></h2>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container center-->\n' +
    '        \n' +
    '        <div class="description-container">\n' +
    '            <div class="description" ng-hide=\'data.description == ""\'>\n' +
    '                <h2 class="medium" markdown="{{data.description}}"></h2>\n' +
    '                <!-- {{dingen.x }}/{{dingen.y}}\n' +
    '                {{dingenTwo.left }}/{{dingenTwo.top}}\n' +
    '                {{magnifier.width}}/{{magnifier.height}} -->\n' +
    '            </div>\n' +
    '            <div class="source" markdown="{{data.source}}" ng-hide=\'data.source == ""\'></div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container right-->\n' +
    '        <div ng-if="data.interactive.external.weblink != undefined && data.interactive.external.weblink != \'\' && data.interactive.wildweetje" class="action-container" ng-click="launchWeblink(data.interactive.external.weblink)">\n' +
    '            <img class="action-icon weetje" src="assets/images/interactives/deepen/wilde_weetjes.svg"></img>\n' +
    '        </div>\n' +
    '        \n' +
    '        <weblink ng-if="data.interactive.external.weblink != undefined && data.interactive.external.weblink != \'\' && !data.interactive.wildweetje" data="data.interactive.external"></weblink>\n' +
    '\n' +
    '    </div>\n' +
    '    <zoom-modal open="open"></zoom-modal>\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/interactives/page_youtube.html',
    '<div class="video">\n' +
    '\n' +
    '    <!--<img class="background" src="assets/images/interactive_bg.png"></img>-->\n' +
    '\n' +
    '    <div class="background-leaf">\n' +
    '        <img ng-show="!data.interactive.wildweetje" class="action-icon" src="assets/images/interactives/video/video.svg" ng-click="toggleVideoOverlay($event)"></img>\n' +
    '        <img img-orientation class="posterimage" ng-src="{{serverUrl + data.interactive.posterimage.image}}"></img>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="bottom-bar">\n' +
    '\n' +
    '         <!--container left-->\n' +
    '        <div class="title-container">\n' +
    '            <h2 class="medium rotated" markdown="{{data.name}}"></h2>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container center-->\n' +
    '        <div class="description-container">\n' +
    '            <div class="description">\n' +
    '                <h2 class="medium" markdown="{{data.description}}"></h2>\n' +
    '            </div>\n' +
    '            <div class="source" markdown="{{data.source}}"></div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container right-->\n' +
    '        <div ng-if="!data.interactive.wildweetje" class="action-container" ng-click="toggleVideoOverlay($event)">\n' +
    '            <img class="action-icon" src="assets/images/interactives/video/video.svg"></img>\n' +
    '            <h2 class="medium" markdown="{{data.interactive.buttonname}}"> »</h2>\n' +
    '            <h2 class="small" markdown="{{data.interactive.duration}}"></h2>\n' +
    '        </div>\n' +
    '        <div ng-if="data.interactive.wildweetje" class="action-container" ng-click="toggleVideoOverlay($event)">\n' +
    '             <img class="action-icon weetje" src="assets/images/interactives/deepen/wilde_weetjes.svg"></img>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '\n' +
    '    <div class="video-overlay" ng-if="videoOverlayVisible">\n' +
    '        <div class="background" ng-click="toggleVideoOverlay($event)"></div>\n' +
    '        <!-- <video class="player" id="player" controls ng-src="{{videourl}}" autoplay>\n' +
    '            <a class="button">SLUIT</a>\n' +
    '        </video> -->\n' +
    '        <iframe ng-if=\'videoOverlayVisible\' class=\'player\' width="100%" height="500" ng-src="{{trustSrc(videourl)}}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>\n' +
    '        \n' +
    '        <button ng-if="data.interactive.altyoutubeurl" class="button ng-scope alt-image sign-language" ng-click="toggleAltVideo()">\n' +
    '            <img ng-if="!altVideo" alt="gebarentaal" ng-src="assets/images/sign_language.svg"></img>\n' +
    '            <img ng-if="altVideo" alt="gebarentaal" ng-src="assets/images/sign_language_active.svg"></img>\n' +
    '        </button>\n' +
    '    </div>\n' +
    '\n' +
    '\n' +
    '</div>');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/interactives/page_youtubeslideshow.html',
    '<div class="slideshow">\n' +
    '    <button class="carousel-nav left" role="btn" aria-label="draai links" ng-click="move(-1)"></button>\n' +
    '    <button class="carousel-nav right" role="btn" aria-label="draai rechts" ng-click="move(+1)"></button>\n' +
    '    \n' +
    '    <div class="carousel-wrapper" ng-init="current = 0;">\n' +
    '        <div class="background-leaf carousel yt-slide" ng-class="getClass($index); " ng-repeat="video in data.interactive.videos">\n' +
    '            <ng-youtube-embed \n' +
    '                videoid="{{\'video\'+$index}}"\n' +
    '                class="player"\n' +
    '                video="video"\n' +
    '                autoplay="false"\n' +
    '                disablekb="true"\n' +
    '                enablejsapi="true"\n' +
    '                width="100%"\n' +
    '                modestbranding="1"\n' +
    '                rel="false"\n' +
    '                showinfo="false"\n' +
    '                start="{{getStartTime(video)}}"\n' +
    '                ng-if="!altVideo || getClass($index) != \'current\'">\n' +
    '            </ng-youtube-embed>\n' +
    '            <ng-youtube-embed \n' +
    '                videoid="{{\'videoalt\'+$index}}"\n' +
    '                class="player"\n' +
    '                video="data.interactive.altvideos[$index]" \n' +
    '                autoplay="false"\n' +
    '                disablekb="true"\n' +
    '                enablejsapi="true"\n' +
    '                width="100%"\n' +
    '                modestbranding="1"\n' +
    '                rel="false"\n' +
    '                showinfo="false"\n' +
    '                start="{{getStartTime(data.interactive.altvideos[$index])}}"\n' +
    '                ng-if="altVideo && getClass($index) == \'current\'">\n' +
    '            </ng-youtube-embed>\n' +
    '            <button ng-if="data.interactive.altvideos[$index] && getClass($index) == \'current\'" class="button ng-scope alt-image sign-language" ng-click="toggleAltVideo()">\n' +
    '                <img ng-if="!altVideo" alt="gebarentaal" ng-src="assets/images/sign_language.svg"></img>\n' +
    '                <img ng-if="altVideo" alt="gebarentaal" ng-src="assets/images/sign_language_active.svg"></img>\n' +
    '            </button>\n' +
    '            <div class="click-block" ng-if="getClass($index) != \'current\'"></div>\n' +
    '        </div>\n' +
    '    </div>\n' +
    '    <div class="bottom-bar">\n' +
    '\n' +
    '         <!--container left-->\n' +
    '        <div class="title-container">\n' +
    '            <h2 class="medium rotated" markdown="{{data.name}}"></h2>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container center-->\n' +
    '        <div class="description-container">\n' +
    '            <div class="description">\n' +
    '                <h2 class="medium" markdown="{{data.description}}"></h2>\n' +
    '            </div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container right-->\n' +
    '        <weblink ng-if="data.interactive.external.weblink != undefined && data.interactive.external.weblink != \'\'" data="data.interactive.external"></weblink>\n' +
    '\n' +
    '    </div>\n' +
    '\n' +
    '</div>\n' +
    '');
}]);
})();

(function(module) {
try {
  module = angular.module('templateCachePartials');
} catch (e) {
  module = angular.module('templateCachePartials', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('/views/exhibit/interactives/page_zoom.html',
    '<div class="zoom" ng-init="scale = 5;">\n' +
    '\n' +
    '    <!--{{data}}-->\n' +
    '    <div class="background-leaf">\n' +
    '       <div class="zoom-padding">\n' +
    '            <img img-orientation alt="{{data.interactive.image.alttext}}" ng-src="{{serverUrl + data.interactive.image.image}}" ng-style="{\'transform\': \'translateX(-50%) translateY(-50%) scale(\' + scale + \') rotate(0.1deg)\' }"></img>\n' +
    '       </div>\n' +
    '    </div>\n' +
    '    <!--<a ng-click="SetScale(1);">+</a>\n' +
    '    <a ng-click="SetScale(-1)">-</a>-->\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '\n' +
    '    <div class="bottom-bar">\n' +
    '\n' +
    '         <!--container left-->\n' +
    '        <div class="title-container">\n' +
    '            <h2 class="medium rotated" markdown="{{data.name}}"></h2>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container center-->\n' +
    '        <div class="description-container">\n' +
    '            <div class="description" ng-hide=\'data.description == ""\'>\n' +
    '                <h2 class="medium" markdown="{{data.description}}"></h2>\n' +
    '            </div>\n' +
    '            <div class="source" markdown="{{data.source}}" ng-hide=\'data.source == ""\'></div>\n' +
    '        </div>\n' +
    '\n' +
    '        <!--container right-->\n' +
    '       <!--<div class="action-container" hold-button="onHoldShortStart($event, $promise,1)" hold-button-delay="2000" style="right:19vh;">\n' +
    '            <button class="circle">+</button>\n' +
    '            <h2 class="medium">ZOOM IN</h2>\n' +
    '       </div>\n' +
    '       <div class="action-container" hold-button="onHoldShortStart($event, $promise,-1)" hold-button-delay="2000">\n' +
    '            <button class="circle">-</button>\n' +
    '            <h2 class="medium">ZOOM OUT</h2>\n' +
    '       </div>-->\n' +
    '        <!--<div class="action-container" ng-click="zoom(1)" style="right:19vh;">\n' +
    '            <button class="circle">+</button>\n' +
    '            <h2 class="medium">ZOOM IN</h2>\n' +
    '       </div>-->\n' +
    '       <div class="action-container">\n' +
    '           <div ng-click="zoom(1)">\n' +
    '                <button class="circle">+</button>\n' +
    '                <h2 class="medium">ZOOM IN</h2>\n' +
    '           </div>\n' +
    '           <div ng-click="zoom(-1)">\n' +
    '                <button class="circle">-</button>\n' +
    '                <h2 class="medium">ZOOM OUT</h2>\n' +
    '           </div>\n' +
    '       </div>\n' +
    '    </div>\n' +
    '</div>\n' +
    '');
}]);
})();
