app.controller('DeeplinkCtrl', function ($scope,$rootScope,Api,KMService,$routeParams, $location) 
{
    console.log("Deeplinking to ", $routeParams.token);
    $scope.kmService = KMService;
    
    Api.Get("collections/open/" + $routeParams.token, function (res) {
        if( res.data && res.data.msg === 'Invalid token') {
            $location.path("/");
            return;
        }
        $rootScope.deeplinkCollection = res.data;
        console.log("calendars/" +  res.data._id);
        $location.path("calendars/" + res.data._id + "/" + $scope.kmService.slugify(res.data.name) ); 
    },function(error){
        console.log(error);
        $location.path("/");
    });

});
