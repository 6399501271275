app.directive('pageZoom', function () {
    return {
        templateUrl: '/views/exhibit/interactives/page_zoom.html',
        replace: true,
        scope: {
            "data": "=data"
        },
        controller: function ($scope, $rootScope) {
            $scope.serverUrl = $rootScope.serverUrl;
            $scope.SetScale = function (i) {

                $scope.scale = clamp($scope.scale + (.3 * i), 1, 5);
            }
            function clamp(num, min, max) {
                return num <= min ? min : num >= max ? max : num;
            }


            $scope.isShortActionDone = false;
            $scope.onHoldShortStart = function ($event, $promise, dir) {
                $promise.then(function (success) {
                    //Called if the promise is resolved, ie the button is hold long enough
                    $scope.isShortActionDone = !$scope.isShortActionDone;
                }, function (reason) {
                    //Called if the promise is rejected, ie the button is not hold long enough
                //    console.log("REJECTED");
                }, function (update) {
                    //This is the progress function, called multiple times before the promise is 
                    // either resolved or rejected.
                    $scope.scale = clamp($scope.scale + ((update/5) * dir), 1.25, 5);
                    //console.log("HOLD", update,$scope.scale);
                })
            }
            $scope.zoom = function(dir){
                $scope.scale = clamp($scope.scale + ((.25 * dir)*$scope.scale), 1.25, 5);
            }
        }

    };
});
