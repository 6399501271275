app.controller('CalendarCollectionsCtrl', function ($scope, $timeout, $rootScope, KMService, Api, FlickityService,$location,Page) {

    Page.setTitle("Digitale Scheurkalender");
    this.$rootScope = $rootScope;
    $scope.kmService = KMService;
    $scope.isCollectionPage = "true";
    
    // console.log($location.search().id);
    $scope.focus = {
        '_id':$location.search().id
    };
    if(typeof $scope.focus._id == 'undefined'){
        $scope.live = {
            'live':true
        };
    }
    //Retrieve collections data
    Api.Get("collections", function (res) {
    //Api.Get("assets/mockdata/collections.json", function (res) {
        //Set data and assign first as current
        $scope.collections = res.data;
        // $scope.currentCollection = res.data[0];
        // console.log(res.data);
        createCarousel(); 
        
    },function(error){
        // console.log(error);
        $location.path("/");
    });

    //Generate Flickity carousel
    function createCarousel() {
        var options = {
            initialIndex: KMService.selectedCollection,
            wrapAround: true,
            setGallerySize: false,
            pageDots: false,
            prevNextButtons: true,
            cellAlign: "center"
        };

        const element = angular.element(document.getElementById('collections'));
        $timeout(() => {
            FlickityService.create(element[0], element[0].id, options);
            $scope.currentCollection = $scope.filteredColllections[0];
        });
    }

    //On carousel stop set textual content based on current carousel
    const settle = $rootScope.$on('Flickity:collections:select', (event, data) => {
        // console.log('Flickity just settled!', event, data, data.instance.selectedIndex);
        $scope.currentCollection = $scope.filteredColllections[data.instance.selectedIndex];
        $scope.$apply();
    });
    const click = $rootScope.$on('Flickity:collections:staticClick', (event, pointer, cellElement, cellIndex) => {
        // console.log('Flickity just selected!', event, pointer, cellElement, cellIndex);
        if(typeof(pointer.cellIndex) != 'undefined'){
            $scope.collectionClicked($scope.filteredColllections[pointer.cellIndex]);
        }
    });

    // 
    $scope.collectionClicked = (collection) => {
        const collectionID = collection._id;
        const collectionName = $scope.kmService.slugify(collection.name);
        // console.log("collectionClicked", collectionID, collectionName);
        
        // KMService.selectedCollection = collectionID;
        // console.log($location);
        $location.search('id', null);
        $location.path("/calendars/" + collectionID + "/" + collectionName );
        $scope.$apply();
    }
});
