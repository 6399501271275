app.service("Api",function($http){
    // var baseApiUrl = "http://192.168.41.17:8080/api/";
    // var baseApiUrl = "http://192.168.10.236:8080/api/";
   var baseApiUrl = "https://digitalescheurkalender.com/api/";
//    var baseApiUrl = "http://localhost:8080/api/";

    this.Get = function(url,callback,error){
        $http.get(baseApiUrl + url).then(function(res){
            console.log('Success',res);
            callback(res);
        },function(err){
            error(err);
            console.log('Error',err);
        });
    }
    this.Post = function(url,data,callback,error){
        console.log("DOIGN A POST",url,data,callback,error);
        $http.post(baseApiUrl + url,data).then(function(res){
            console.log('Success',res);
            callback(res);
        },function(err){
            error(err);
            console.log('Error',err);
        });
    }
});