app.controller('CalendarsCtrl', function ($scope, $rootScope, $location, KMService, Api,$routeParams,$interval,Page) 
{


    $scope.kmService = KMService;
    $scope.back = "collections";
    $scope.colofonVisible = false;

    $scope.goBack = function(){
        if($rootScope.free){
            $location.path("/");
        }
        else{
            $location.path("/collections");
        }
    }

    function randomImage(){
        $scope.partner = $scope.partner+1 == $scope.collection.partners.length ? 0 : $scope.partner+1;
    }
    $scope.calendarClicked = function(page)
    {
        const pageID = page._id;
        const pageName = $scope.kmService.slugify(page.name);
        console.log("Calendar clicked",pageID);
        $location.search('id', null);
        $location.path("calendar/page/" + pageID + "/" + $routeParams.name + "/" + pageName);
    }
    $scope.getFunny = function(){
        var rand = Math.random();
        // console.log(rand);
        if(rand < .2){
            return 'funny-1';
        }
        else if(rand > 0.8){
            return 'funny-2';
        }
        return '';
    }
    
    $scope.toggleColofon = function()
    {
        $scope.colofonVisible = !$scope.colofonVisible;
    }
    $scope.q = '';
    $scope.currentPagination = 0;


    var url = $rootScope.free ? 'collections/free/' : 'collections/';
    if( $rootScope.deeplinkCollection ) {
        
        Page.setTitle($rootScope.deeplinkCollection.name);
        $scope.calendars = $rootScope.deeplinkCollection.calendars;
        $scope.collection = $rootScope.deeplinkCollection;
        // $scope.currentCalendar = $rootScope.deeplinkCollection[0];
        $scope.partner = 0;
        $interval(randomImage,3000);

        angular.forEach($scope.calendars,function(value){
            value.class = $scope.getFunny();
        });
    }
    else {
        Api.Get(url+$routeParams.id, function (res) {
            //Set data and assign first as current
            Page.setTitle(res.data.name);
            $scope.calendars = res.data.calendars;
            $scope.collection = res.data;
            $scope.currentCalendar = res.data[0];
            $scope.partner = 0;
            $interval(randomImage,3000);
    
            angular.forEach($scope.calendars,function(value){
                value.class = $scope.getFunny();
            });
        },function(error){
            console.log(error);
            $location.path("/");
        });
    }

});
