
// ----------------------------------------------------------------------------
// Initialise the application.
// Set the name of the module here and define dependencies
// ----------------------------------------------------------------------------

const appName = "scheurkalender";
const modules = ['ngRoute', 'templateCachePartials', 'bc.Flickity', 'hmTouchEvents','angular-google-analytics','ngYoutubeEmbed', 'ngSanitize'];

// Requirements
// ----------------------------------------------------------------------------
// EXIF                =   require('exif-js');
// $                   =   require('jquery');
var angular         =   require("angular");
var angularRoute    =   require('angular-route');
                        require('angular-flickity');
                        require("angular-google-analytics");
                        require('angular-hammer');
                        require('angular-sanitize');
                        require('gsap');
                        require('floodfill');
                        require('../dist/js/templateCachePartials.js');
                        require('ng-youtube-embed');
                        // require('viewport-units-buggyfill').init();

// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------


var app = angular.module( appName, modules );
app.config(['$httpProvider', function($httpProvider) { $httpProvider.defaults.withCredentials = true; }]);
app.config(['AnalyticsProvider', function (AnalyticsProvider) {
   // Add configuration code as desired
   AnalyticsProvider.setAccount('UA-97247054-1').ignoreFirstPageLoad(true);  //UU-XXXXXXX-X should be your tracking code
}]).run(['Analytics', function(Analytics) { }]);
module.exports = app;

// var hacks = require('viewport-units-buggyfill/viewport-units-buggyfill.hacks');
// require('viewport-units-buggyfill').init({
//   hacks: hacks
// });

// var viewportUnitsBuggyfill = require('viewport-units-buggyfill');

// viewportUnitsBuggyfill.init();

// document.oncontextmenu = document.body.oncontextmenu = function() {return false;}
window.oncontextmenu = function(event) {
     event.preventDefault();
     event.stopPropagation();
     return false;
};

var classNames = [];
if(navigator.userAgent.indexOf("Edge") != -1){
    // alert("EDGE")
    classNames.push('browser-edge');
}
if(navigator.userAgent.indexOf("Trident") != -1){
    // alert("IE");
    classNames.push('browser-ie');
}
if (navigator.userAgent.match(/(iPad|iPhone|iPod)/i)){
    classNames.push('device-ios');
    ios = true;
}
if (navigator.userAgent.match(/android/i)) classNames.push('device-android');

var html = document.getElementsByTagName('html')[0];

if (classNames.length) classNames.push('on-device');
if (html.classList) html.classList.add.apply(html.classList, classNames);