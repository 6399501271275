app.directive('pageYoutubeslideshow', function () {
    return {
        templateUrl: '/views/exhibit/interactives/page_youtubeslideshow.html',
        replace : true,
        scope:{
            "data":"=data"
        },
        controller: function ($scope,$rootScope) {
            $scope.serverUrl = $rootScope.serverUrl;
            $scope.altVideo = false;

            $scope.currentVid = $scope.data.interactive.altvideos[0];

            $scope.getClass = function(idx){
                if($scope.current == idx){
                    return 'current';
                }
                else if(repeat($scope.current+1,$scope.data.interactive.videos.length) == idx){
                    return 'right';
                }
                else if(repeat($scope.current-1,$scope.data.interactive.videos.length)  == idx){
                    return 'left';
                }
            }
            for(var i in $scope.data.interactive.videos){
                $scope.data.interactive.videos[i].video = $rootScope.trustSrc($rootScope.serverUrl + $scope.data.interactive.videos[i].image);
                // console.log($scope.data.interactive.videos[i]);
            }
            $scope.move = function(dir){
                $scope.current = repeat($scope.current + dir,$scope.data.interactive.videos.length);
                $scope.altVideo = false;
                // stopAll();
            }
            function repeat(number,length){
                if(number > -1){
                    return number % length;
                }
                else{
                    return length -1;
                }
            }

            $scope.toggleAltVideo = function()
            {
                console.log("toggle video");
                $scope.altVideo = !$scope.altVideo;
            }

            $scope.getStartTime = function(url){
                //TIMESTAMP EXTRACTION

                var pattern = /t=(.*s)/g;
                var match = url.match(pattern);
                if(match == null){
                    return 0;
                }
                var timestamp = match[0];
                // console.log(timestamp);
                if(timestamp.length > 0){
                    var total = 0;

                    var mPattern = /(\d+)m/g;
                    var minutes =  timestamp.match(mPattern)[0];
                    if(minutes.length > 0){
                        var number = parseInt(minutes.substring(0, minutes.length - 1)*60);
                        total += number;
                        // console.log(total,number,minutes);
                    }

                    var secPattern = /(\d+)s/g;
                    var seconds =  timestamp.match(secPattern)[0];
                    if(seconds.length > 0){
                        var number = parseInt(seconds.substring(0, seconds.length - 1));
                        total += number;
                        // console.log(total,number,seconds)
                    }
                    return total;

                }
                return 0;
            }


            // function stopAll(){
            //     var vids = document.getElementsByTagName('video') 
            //     // vids is an HTMLCollection
            //     for( var i = 0; i < vids.length; i++ ){ 
            //         vids[i].classList.remove("playing");
            //         vids[i].pause();
            //     }
            // }
            // $scope.toggle = function(event){
            //     console.log(event);
            //     if(event.target.paused){
            //         playVideo(event.target);
            //     }
            //     else{
            //         pauseVideo(event.target);
            //     }
            // }
            // function playVideo(el){
            //     console.log("Play",el);
            //     el.addEventListener('ended',function(){ pauseVideo(el) },false);
            //     el.classList.add('playing');
            //     el.play();
            // }
            // function pauseVideo(el){
            //     console.log("Pause",el);
            //     el.classList.remove('playing');
            //     el.pause();
            // }
        }
    };
});

