app.directive('pageQuiz', function () {
    return {
        templateUrl: '/views/exhibit/interactives/page_quiz.html',
        replace : true,
        scope:{
            "data":"=data"
        },
        controller: function ($scope,$rootScope) { $scope.serverUrl = $rootScope.serverUrl;
            
            $scope.values = [];
            $scope.buttons = document.getElementsByClassName('quizimage');

            $scope.selectedText;
            $scope.images = $scope.data.interactive.images;

            for (let i= $scope.images.length-1;i>0;i--){
                if ( $scope.images[i] == undefined || $scope.images[i] == "" ){
                     $scope.images.splice(i, 1);
                }
            }


            
            if ($scope.images.length==1)
            {
                $scope.adjustedDimensions = {'width':'100%', 'height':'100%'};
            }
            else
            {
                $scope.adjustedDimensions = {'width':'50%', 'height':'50%'};
            }

           // $scope.adjustedWidth = {'width':100/ $scope.images.length + '%'};
            // $scope.adjustedWidth = {'max-width':75 + $scope.data.interactive.images.length + 'vh'};

            for(let i=0;i<$scope.values.length;i++)
            {
                values.push(0);
            }

            $scope.$watch('values', function () {
                for(let i=0;i<$scope.values.length;i++)
                {
                    if ($scope.values[i] == 1){
                        $scope.buttons[i].style.borderStyle = "solid";
                        $scope.buttons[i].style.borderWidth = "1vh";
                        $scope.buttons[i].style.borderColor = "#009EE2";
                        $scope.buttons[i].style.margin = "-1vh";
                        $scope.buttons[i].style.zIndex = "5000";
                    }
                    else
                    {
                        $scope.buttons[i].style.borderStyle = "none";
                        $scope.buttons[i].style.margin = "0";
                        $scope.buttons[i].style.zIndex = "0";
                    }
                }
                
            }, true);
                        
            $scope.onClick = function(index, txt)
            {
                
                console.log("onClick " + index);
                console.log("onClick " + txt);

                // reset selection
                for (let i = 0; i < $scope.buttons.length; i++) {
                    $scope.values[i] = 0;
                }
                // set selected
                $scope.values[index] = 1;
                $scope.selectedText = txt; 
            }
            
        }
    };
});
